import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
//redux
import { useDispatch } from "react-redux";
import {LOGOUT, LOGIN_SUCCESS} from "./store/auth/constants";

//firebase
import firebase from "./firebase/config";
import { removeToken } from "./utils/authManager";

//Vercel analytics
import { Analytics } from '@vercel/analytics/react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();
  
  useEffect(() => {
  
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: user.refreshToken,
        })
      }else{
        removeToken()
        dispatch({
          type: LOGOUT,
        })
      }
    });
    return () => {
      unsubscribe();
    };                          // eslint-disable-next-line
  }, [])

  return (
      <Router>
        <div className="App">
          <Routes />
          <ToastContainer />
          <Analytics/>
        </div>
      </Router>
  );
}

export default App;
