const ModalWrapper = ({
	children,
	title,
	id,
	titleId,
	size = "lg"
}) => {
	return (
		<div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={titleId} aria-hidden="true">
			<div className={`modal-dialog modal-${size}`} role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id={titleId}>{title}
						</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					{children}
				</div>
			</div>
		</div>
	)
}
export default ModalWrapper