import React from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
import { teamStatsSetLanguage } from "../../store/teamStats/actions";
//constants
import languages from "../../constants/languages";
//components
import SelectPicker from "../common/formFields/SelectPicker";
import Button from "../common/formFields/Button";

const Header = () => {
	const dispatch = useDispatch()
	const {language} = useSelector((state) => state.teamStats)
	return (
		<div className='row'>
			<div className='col-md-12 my-5'>
				<div className='col-md-12 bg-dark shadow-sm rounded'>
					<form className='form-cust' style={{ justifyContent: "space-between" }}>
						<SelectPicker 
							label={""}
							value={language}
							className="top-input-drop"
							onChange={e => dispatch(teamStatsSetLanguage(e.target.value))}
							options={languages.filter(lang => !["da", "sv"].includes(lang.value))}
						/>
						<Button 
							label="Create New Banner"
							type="button"
							className='btn btn-primary top-btn'
							data-toggle="modal"
							data-target="#createTeamStatisticModal"
						/>
					</form>
				</div>
			</div>
		</div>
	)
}
export default Header;