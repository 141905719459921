import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Swal from 'sweetalert2'
import { eventOffers, fetchCategories } from '../dataServices/Services'
import Loader from './Loader'
import InitialMessage from './Message'
import SvgModal from './SvgModal'
import SvgComboModal from './SvgComboModal'
import BetCards from './BetCards'
import Bucket from './Bucket/Bucket'
import Switch from './Switch/Switch'
//Spectate
import SpectateForm from './Spectate/SpectateForm'
import SpectateTable from './Spectate/SpectateTable'

import { connect } from 'react-redux'
import { bucketSetAPIType, bucketReset } from "../store/bucket/actions";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '90%'
  },
  headText: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
    borderColor: '#fff'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  tabRow: {
    width: '90%',
    backgroundColor: '#222',
    borderColor: '#0000'
  },
  tabRowInner: {
    borderColor: '#0000'
  }
}))

function BasicTable(props) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState()
  const [events, setEvents] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [eventNumber, setEventNumber] = useState('')
  const [language, setLanguage] = useState('it_IT')
  const [selectedBet, setSelectedBet] = useState({})
  const [selectedSport, setSelectedSport] = useState('FOOTBALL')
  const [accordian, setAccordian] = useState(null)
  const handleSubmitForm = e => {
    e.preventDefault()
    setLoading(true)
    fetchCategories(selectedSport, '1617710577749', language).then(res => {
      if (res.data?.categoryGroups) {
        if (res.data.categoryGroups.length) {
          if (res.data.categoryGroups[0]?.categories?.length) {
            setCategories([...res.data.categoryGroups[0].categories])
            eventOffers(eventNumber, language)
              .then(res => {
                if (res.status === 200) {
                  if (res.data?.betoffers) {
                    setData(res.data.betoffers)
                    setEvents(res.data.events)
                  } else {
                    setData([])
                  }
                } else {
                  setData([])
                }
              })
              .catch(err => {
                alert('NetWork Error')
              })
              .finally(() => {
                setLoading(false)
              })
          }
        }
      }
    })
  }
  const getUniqueMappings = data => {
    const unique = [...new Set(data.map(item => item.criterionId))]
    return unique
  }
  const extractBetOffer = criterionId => {
    return data.filter(item => item.criterion.id === criterionId)
  }
  const getMappingCount = data => {
    let count = 0 // eslint-disable-next-line
    getUniqueMappings(data).map(val => {
      let betOffers = extractBetOffer(val)
      count += betOffers.length
    })
    return count
  }
  const sportsKambi = [
    {
      sport: 'FOOTBALL',
      id: 1000093190,
      italianName: 'Calcio',
    },
    {
      sport: 'BASKETBALL',
      id: 1000093190,
      italianName: 'Basket',
    },
    {
      sport: 'TENNIS',
      id: 1000093190,
      italianName: 'Tennis',
    },
    {
      sport: 'MARTIAL_ARTS',
      id: 1000093883,
      italianName: 'UFC/MMA',
    },
    {
      sport: 'VOLLEYBALL',
      id: 1000302582,
      italianName: 'Pallavolo',
    },
    {
      sport: 'WATER_POLO',
      id: 1000302455,
      italianName: 'Pallanuoto',
    },
    {
      sport: 'HANDBALL',
      id: 1000093205,
      italianName: 'Pallamano',
    },
    {
      sport: 'CYCLING',
      id: 1000093233,
      italianName: 'Ciclismo',
    }
  ];
  const handleChangeAPIType = () => {
    if (props.items.length > 0) {
      Swal.fire({
        title: 'Your bucket will be reset after this operation.',
        text: "Do you want to continue?",
        showDenyButton: true,
        confirmButtonText: `Continue`,
        denyButtonText: `Cancel`,
        icon: "warning"
      }).then((result) => {
        if (result.isConfirmed) {
          props.bucketSetAPIType(props.apiType === "kambi" ? "spectate" : "kambi");
          props.bucketReset();
        }
      })
    } else {
      props.bucketSetAPIType(props.apiType === "kambi" ? "spectate" : "kambi")
    }
  }
  return (
    <div className='container-fluid'>
      {' '}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 d-flex mt-3'>
            <Switch
              labels={['kambi', 'spectate']}
              selected={props.apiType}
              onChange={() => handleChangeAPIType()}
            />
          </div>
          <div className='col-md-12 my-5'>
            <div className='col-md-12 bg-dark shadow-sm rounded'>
              {props.apiType === 'kambi' ? (
                <form className='form-cust' onSubmit={handleSubmitForm}>
                  <select
                    className='top-input-drop'
                    style={{ maxWidth: 150, marginRight: 15 }}
                    onChange={e => setSelectedSport(e.target.value)}
                    value={selectedSport}
                  >
                    {sportsKambi.map((sp, i) => (
                      <option
                        value={sp.sport}
                        className='bg-dark'
                        key={`sport-${i}`}
                      >
                        {language === 'it_IT' ? sp.italianName : sp.sport}
                      </option>
                    ))}
                  </select>
                  <input
                    id='time'
                    type='number'
                    placeholder='Enter Event ID'
                    className='top-input'
                    onChange={e => setEventNumber(e.target.value)}
                    value={eventNumber}
                    required
                  />
                  <select
                    className='top-input-drop'
                    style={{ maxWidth: 150, marginRight: 15 }}
                    onChange={e => setLanguage(e.target.value)}
                    value={language}
                  >
                    <option value='it_IT' className='bg-dark'>
                      Italian
                    </option>
                  </select>
                  <button className='btn btn-primary top-btn' type={'submit'}>
                    Submit
                  </button>
                </form>
              ) : (
                <SpectateForm language={language} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          {props.apiType === 'kambi' ? (
            <TableContainer component={Paper} className='bg-dark'>
              <Table className={classes.table} aria-label='simple table'>
                <TableBody>
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100px',
                        width: '70vw',
                        margin: 'auto'
                      }}
                    >
                      <Loader />
                    </div>
                  ) : !categories.length ? (
                    <InitialMessage />
                  ) : (
                    // eslint-disable-next-line
                    categories.map((cat, i) => {
                      let count = 0 // eslint-disable-next-line
                      getUniqueMappings(cat.mappings).map(item => {
                        if (extractBetOffer(item).length) {
                          return count++
                        }
                      })
                      if (count > 0)
                        return (
                          <>
                            <TableRow className={classes.tabRow} key={i}>
                              <TableCell
                                colSpan={3}
                                scope='row'
                                className={classes.tabRowInner}
                              >
                                <div className='accordion-item'>
                                  <button
                                    className='accordion-button btn btn-dark btn-accordian px-4 py-2 collapsed w-100'
                                    type='button'
                                    data-bs-target={`#flush-collapse${i}`}
                                    onClick={() => {
                                      setAccordian(accordian === i ? null : i)
                                    }}
                                  >
                                    <div className='row'>
                                      <div className='col-10'>
                                        <div className='row btn-colasable'>
                                          <h5 className='col-12 text-left'>
                                            {cat.name}
                                          </h5>
                                        </div>
                                      </div>
                                      <div className='col-2'>
                                        <div className='row btn-colasable'>
                                          <p className='col-10 text-align-left'>
                                            {getMappingCount(cat.mappings)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                  {accordian === i && (
                                    <div
                                      id={`flush-collapse${i}`}
                                      className='accordion-collapse w-100 bg-white p-2'
                                      aria-labelledby='flush-headingOne'
                                      data-bs-parent='#accordionFlushExample'
                                    >
                                      {getUniqueMappings(cat.mappings).map(
                                        (val, index) => (
                                          <BetCards
                                            length={
                                              extractBetOffer(val)?.length
                                            }
                                            betOffer={
                                              extractBetOffer(val)
                                            }
                                            event={
                                              events.length ? events[0] : {}
                                            }
                                            key={index}
                                            setSelectedBet={value =>
                                              setSelectedBet(value)
                                            }
                                            lang={language}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <SpectateTable setSelectedBet={value => setSelectedBet(value)} />
          )}
        </div>
      </div>
      <SvgModal
        bet={selectedBet}
        language={props.apiType === "kambi" ? language : props.languageSpectate}
      />
      <SvgComboModal language={language} />
      <Bucket language={language} />
    </div>
  )
}
const mapStateToProps = state => ({
  languageSpectate: state.spectate.language,
  apiType: state.bucket.apiType,
  items: state.bucket.items
})
const mapDispatchToProps = {
  bucketSetAPIType,
  bucketReset
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicTable)
