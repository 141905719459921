import {
  BUCKET_SET_LOADING,
  BUCKET_SET_ITEM,
  BUCKET_DELETE_ITEM,
  BUCKET_UPDATE_SORTING_ORDER,
  BUCKET_SET_API_TYPE,
  BUCKET_RESET
} from "./constants";

/* Bucket actions */
export function bucketSetLoading(loading) {
  return {
    type: BUCKET_SET_LOADING,
    payload: loading,
  };
}

export function bucketSetItem(params) {
  return {
    type: BUCKET_SET_ITEM,
    payload: params,
  };
}

export function bucketDeleteItem(params) {
  return {
    type: BUCKET_DELETE_ITEM,
    payload: params,
  };
}

export function bucketUpdateSortingOrder(params) {
  return {
    type: BUCKET_UPDATE_SORTING_ORDER,
    payload: params,
  };
}

export function bucketSetAPIType(params) {
  return {
    type: BUCKET_SET_API_TYPE,
    payload: params,
  };
}

export function bucketReset() {
  return {
    type: BUCKET_RESET
  };
}