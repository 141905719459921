import React from "react";
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Loader from '../Loader'
import { FiTrash } from 'react-icons/fi';
import Swal from "sweetalert2";

//redux
import { connect } from "react-redux";
import {deleteNetWorth, netWorthSetDetails } from "../../store/netWorth/actions";
const useStyles = makeStyles(theme => ({
	table: {
		minWidth: '90%'
	},
	headText: {
		fontWeight: 'bold',
		fontSize: '14px',
		color: '#fff'
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 150,
		borderColor: '#fff'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	tabRow: {
		width: '90%',
		backgroundColor: '#222',
		borderColor: '#0000'
	},
	tabRowInner: {
		borderColor: '#0000'
	}
}))
const NetWorthTable = (props) => {
	const classes = useStyles();
	const handleDelete = async(id) =>{
		try{
			Swal.fire({
				title: 'Are you sure want to delete this record?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			  }).then(async(result) => {
				if (result.isConfirmed) {
				  await props.deleteNetWorth(id)
				}
			  })
		}catch(err){
			Swal.fire("Something went wrong", "", "error")
		}
	}
	return (
		<TableContainer component={Paper} className='bg-dark'>
			<Table className={classes.table} aria-label='simple table'>
				<TableBody>
					{props.loading ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100px',
								width: '70vw',
								margin: 'auto'
							}}
						>
							<Loader />
						</div>
					)
						// : !categories.length ? (
						// 	<InitialMessage />
						// )
						: (
							// eslint-disable-next-line
							<>
								{props.netWorths.map((item, i) => (
									<TableRow className={classes.tabRow} key={i}>
										<TableCell
											colSpan={3}
											scope='row'
											className={classes.tabRowInner}
										>
											<div className='accordion-item d-flex'>
												<button
													className='accordion-button btn btn-dark btn-accordian px-4 py-2 collapsed w-100'
													type='button'
													data-toggle="modal" 
													data-target="#showNetWorthModal"
													onClick={()=>props.netWorthSetDetails(item)}
												>
													<div className='row'>
														<div className='col-10'>
															<div className='row btn-colasable'>
																<h5 className='col-12 text-left'>
																	{item.name}
																</h5>
															</div>
														</div>
														<div className='col-2'>
															<div className='row btn-colasable'>
																<p className='col-10 text-align-left'>
																	{/* {getMappingCount(cat.mappings)} */}
																	€{item.yearlyIncome}
																</p>
															</div>
														</div>
													</div>
												</button>
												<button 
													className="btn btn-primary btn-sm ml-2"
													onClick={()=>handleDelete(item.key)}
												>
													<FiTrash color={"#fff"}/>
												</button>
											</div>
										</TableCell>
									</TableRow>
								))}
							</>
						)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
const mapStateToProps = (state) => ({
	loading: state.netWorth.loading,
	netWorths: state.netWorth.netWorths
});
const mapDispatchToProps = {
	deleteNetWorth,
	netWorthSetDetails
};
export default connect(mapStateToProps, mapDispatchToProps)(NetWorthTable);