import daDKTranslation from "../locales/da_DK";
import deDETranslation from "../locales/de_DE";
import svSETranslation from "../locales/sv_SE";
import esESTranslation from "../locales/es_ES";
import roROTranslation from "../locales/ro_RO";
import enUSTranslation from "../locales/en_US";
import itITTranslation from "../locales/it_IT";

const GetButtonName = (language, isExpired = false) => {
  switch (language) {
    case "es_ES":
      return isExpired
        ? esESTranslation["Bet Expired"]
        : esESTranslation["Bet Now"];
    case "it_IT":
      return isExpired
        ? itITTranslation["Bet Expired"]
        : itITTranslation["Bet Now"];
    case "da_DK":
      return isExpired
        ? daDKTranslation["Bet Expired"]
        : daDKTranslation["Bet Now"];
    case "en_US":
      return isExpired
        ? enUSTranslation["Bet Expired"]
        : enUSTranslation["Bet Now"];
    case "en_CA":
      return `BET NOW!`;
    case "de_DE":
      return isExpired
        ? deDETranslation["Bet Expired"]
        : deDETranslation["Bet Now"];
    case "sv_SE":
      return isExpired
        ? svSETranslation["Bet Expired"]
        : svSETranslation["Bet Now"];
    case "sv_Mr_Green":
      return isExpired
        ? svSETranslation["Bet Expired"]
        : svSETranslation["Bet Now"];
    case "ro_RO":
      return isExpired
        ? roROTranslation["Bet Expired"]
        : roROTranslation["Bet Now"];
    default:
      return `BET HERE`;
  }
};
export default GetButtonName;
