import deDETranslation from "../locales/de_DE";
import roROTranslation from "../locales/ro_RO";
import enUSTranslation from "../locales/en_US";
import itITTranslation from "../locales/it_IT";

export const PREDICTION_DATA_POINTS = (language = "en_US") => {
  let locale = {};
  if (language === "en") {
    locale = enUSTranslation;
  } else if (language === "ro") {
    locale = roROTranslation;
  } else if (language === "de") {
    locale = deDETranslation;
  } else if (language === "it") {
    locale = itITTranslation;
  }
  return [
    { label: locale["Strength"], value: "form", home: 0, away: 0 },
    {
      label: locale["Attacking Potential"],
      value: "att",
      home: 0,
      away: 0,
    },
    {
      label: locale["Defensive Potential"],
      value: "def",
      home: 0,
      away: 0,
    },
    {
      label: locale["Poisson Distribussion"],
      value: "poisson_distribution",
      home: 0,
      away: 0,
    },
    { label: locale["Strength H2H"], value: "h2h", home: 0, away: 0 },
    { label: locale["Goals H2H"], value: "goals", home: 0, away: 0 },
    { label: locale["Wins the Game"], value: "total", home: 0, away: 0 },
  ];
};
