import React, { useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
//redux
import { connect } from "react-redux";
import { createNetWorth } from "../../store/netWorth/actions";

const CreateNetWorthModal = (props) => {
	const [state, setState] = useState({
		name: "",
		yearlyIncome: ""
	})
	const [btnLoading, setBtnLoading] = useState(false);

	const handleSubmitForm = async (e) => {
		try {
			e.preventDefault();
			setBtnLoading(true);
			await props.createNetWorth(state);
			$("#createNetWorthModal").modal("hide");
			$('.modal-backdrop').remove();
			Swal.fire("Created Successfully", "", "success")
		} catch (err) {
			console.log(err);
			Swal.fire("Something went wrong", "", "error")
		} finally {
			setBtnLoading(false);
		}
	}
	return (
		<div className="modal fade" id="createNetWorthModal" tabindex="-1" role="dialog" aria-labelledby="createNetWorthModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="createNetWorthModalLabel">Build net worth banner</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close" id="createNetWorthModalClose">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<form onSubmit={handleSubmitForm}>
						<div className="modal-body" style={{ justifyContent: "space-around" }}>
							<div className="form-group">
								<label htmlFor="net_worth_name">Name</label>
								<input
									type="text"
									className="form-control"
									id="net_worth_name"
									placeholder="Enter name"
									required
									value={state.name}
									onChange={(e) => setState({ ...state, name: e.target.value })}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="net_worth_income">Yearly income <i>(in EURO)</i></label>
								<input
									type="number"
									className="form-control"
									id="net_worth_income"
									placeholder="Enter yearly income"
									required
									value={state.yearlyIncome}
									onChange={(e) => setState({ ...state, yearlyIncome: e.target.value })}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button 
								type="submit" 
								className="btn btn-primary"
								disabled={btnLoading}
							>
								{btnLoading ? "..." : "Save Banner"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
	createNetWorth
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateNetWorthModal);