import React, { useState, useEffect } from "react";
import XMLParser from "react-xml-parser";
import {
  fetchSpectateCategories,
  fetchSpectateTournaments,
  // fetchSpectateEvents
} from "../../dataServices/Services";

import { connect } from "react-redux";
import {
  fetchEvents,
  spectateSetData,
  spectateSetLanguage,
  spectateSetBetType,
  spectateSetCategory,
  spectateSetSport,
  spectateSetTournament,
} from "../../store/spectate/actions";

const SpectateForm = (props) => {
  const [spectateCategories, setSpectateCategories] = useState([]);
  const [selectedSportSpectate, setSelectedSportSpectate] = useState(null);
  const [selectedCategorySpectate, setSelectedCategorySpectate] = useState("");
  const [spectateTournaments, setSpectateTournaments] = useState([]);
  const [selectedTournamentSpectate, setSelectedTournamentSpectate] =
    useState("");
  const sportsSpectate = [
    {
      sport: "FOOTBALL ",
      id: 2,
      germanName: "Fußball",
      englishName: "Football",
      swedenName: "Fotboll",
      spanishName: "Fútbol",
      danishName: "Fodbold",
      romanianName: "Futboll",
      canadianName: "Soccer",
    },
    {
      Sport: "ATHLETICS",
      id: 404,
      germanName: "Leichtathletik",
      englishName: "Athletics",
      canadianName: "Athlétisme",
      swedenName: "Friidrott",
      spanishName: "Atletismo",
      danishName: "Atletik",
      romanianName: "Atletism",
    },
    {
      sport: "ANTEPOST_RACING",
      id: -1,
      germanName: "Antepost Racing",
      englishName: "Antepost Racing",
      canadianName: "Antepost Racing",
      swedenName: "Antepost Racing",
      spanishName: "Antepost Racing",
      danishName: "Antepost Racing",
      romanianName: "Antepost Racing",
    },
    {
      sport: "BASEBALL",
      id: 363,
      germanName: "Baseball",
      englishName: "Baseball",
      canadianName: "Baseball",
      swedenName: "Baseboll",
      spanishName: "Béisbol",
      danishName: "Baseball",
      romanianName: "Baseball",
    },
    {
      sport: "BASKETBALL",
      id: 229,
      germanName: "Basketball",
      englishName: "Basketball",
      canadianName: "Basketball",
      swedenName: "Basketboll",
      spanishName: "Baloncesto",
      danishName: "Basketball",
      romanianName: "Basketball",
    },
    {
      sport: "BOXING",
      id: 354,
      germanName: "Boxen",
      englishName: "Boxing",
      canadianName: "Boxing",
      swedenName: "Boxning",
      spanishName: "Boxeo",
      danishName: "Boksning",
      romanianName: "Boxing",
    },
    {
      sport: "TENNIS",
      id: 4,
      germanName: "Tennis",
      englishName: "Tennis",
      canadianName: "Tennis",
      swedenName: "Tennis",
      spanishName: "Tenis",
      danishName: "Tennis",
      romanianName: "Tenis",
    },
    {
      sport: "GOLF",
      id: 3,
      germanName: "Golf",
      englishName: "Golf",
      canadianName: "Golf",
      swedenName: "Golf",
      spanishName: "Golf",
      danishName: "Golf",
      romanianName: "Golf",
    },
    {
      sport: "MARTIAL_ARTS",
      id: 438,
      germanName: "UFC/MMA",
      englishName: "UFC/MMA",
      canadianName: "UFC/MMA",
      swedenName: "UFC/MMA",
      spanishName: "UFC/MMA",
      danishName: "UFC/MMA",
      romanianName: "UFC/MMA",
    },
    {
      sport: "ICE_HOCKEY",
      id: 362,
      germanName: "Eishockey",
      englishName: "Ice Hockey",
      canadianName: "Hockey",
      swedenName: "Ishockey",
      spanishName: "Hockey sobre hielo",
      danishName: "Ishockey",
      romanianName: "Hochei",
    },
    {
      sport: "HORSE_RACING",
      id: -2,
      germanName: "Pferderennen",
      englishName: "Horse Racing",
      canadianName: "Horse Racing",
      swedenName: "Hästkapplöpning",
      spanishName: "Horse Racing",
      danishName: "Hestevæddeløb",
      romanianName: "Horse Racing",
    },
    {
      sport: "AMERICAN_FOOTBALL",
      id: 6,
      germanName: "Amerikanischer Fußball",
      englishName: "American Football",
      canadianName: "Football",
      swedenName: "Amerikansk fotboll",
      spanishName: "Fútbol Americano",
      danishName: "Amerikansk fodbold",
      romanianName: "Fotboll American",
    },
    {
      sport: "CYCLING",
      id: 418,
      germanName: "Radfahren",
      englishName: "Cycling",
      canadianName: "Cycling",
      swedenName: "Cykling",
      spanishName: "Ciclismo",
      danishName: "Cykling",
      romanianName: "Ciclismo",
    },
    {
      sport: "RUGBY_LEAGUE",
      id: 446,
      germanName: "Rugby League",
      englishName: "Rugby League",
      canadianName: "Rugby League",
      swedenName: "Rugby League",
      spanishName: "Rugby League",
      danishName: "Rugby League",
      romanianName: "Rugby League",
    },
    {
      sport: "RUGBY_UNION",
      id: 447,
      germanName: "Rugby Union",
      englishName: "Rugby Union",
      canadianName: "Rugby Union",
      swedenName: "Rugby Union",
      spanishName: "Rugby Union",
      danishName: "Rugby Union",
      romanianName: "Rugby Union",
    },
    {
      sport: "ESPORTS",
      id: 8229,
      germanName: "eSports",
      englishName: "eSports",
      canadianName: "eSports",
      swedenName: "eSports",
      spanishName: "eSports",
      danishName: "eSports",
      romanianName: "eSports",
    },
    {
      sport: "MOTORSPORT",
      id: 439,
      englishName: "Motor Racing", // motor racing in all languages
      germanName: "Motor Racing",
      canadianName: "Motor Racing",
      swedenName: "Motor Racing",
      spanishName: "Motor Racing",
      danishName: "Motor Racing",
    },
    // {
    // sport: "ODDS_BOOST",
    // id: 8337,
    // germanName: "Odds Boost",
    // englishName: "Odds Boost",
    // canadianName: "Odds Boost",
    // swedenName: "Odds Boost",
    // spanishName: "Odds Boost",
    // danishName: "Odds Boost",
    // romanianName: "Odds Boost",
    // },
  ];
  const handleFetchSpectateCategories = async (sportId) => {
    let catsTemp = [];
    fetchSpectateCategories(sportId).then((res) => {
      var xml = new XMLParser().parseFromString(res.data);
      if (Array.isArray(xml.children)) {
        // eslint-disable-next-line
        xml.children.map((child) => {
          if (typeof child.attributes === "object") {
            catsTemp.push(child.attributes);
          }
        });
      }
      setSpectateCategories([...catsTemp]);
    });
  };
  const handleFetchSpectateTournaments = async (sportId, catId) => {
    let tournamentsTemp = [];
    fetchSpectateTournaments(sportId, catId).then((res) => {
      // eslint-disable-next-line
      var xml = new XMLParser().parseFromString(res.data);
      if (Array.isArray(xml.children)) {
        // eslint-disable-next-line
        xml.children.map((child) => {
          if (Array.isArray(child.children)) {
            // eslint-disable-next-line
            child.children.map((ch) => {
              if (typeof ch.attributes === "object") {
                tournamentsTemp.push(ch.attributes);
              }
            });
          }
        });
      }
      setSpectateTournaments([...tournamentsTemp]);
    });
  };
  const handleSubmitFormSpectate = (e) => {
    e.preventDefault();
    // console.log('selectedSportSpectate:', selectedSportSpectate);
    let link =
      selectedSportSpectate > 0
        ? `https://proxy.oddin.app/${selectedTournamentSpectate.replace(
            /&amp;/g,
            "&"
          )}`
        : selectedSportSpectate == -2
        ? `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/horse_racing_feed.php?token=d41d8cd98f00b204e9800998ecf8427e`
        : selectedSportSpectate == -1
        ? `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/antepost_racing_feed.php?token=d41d8cd98f00b204e9800998ecf8427e`
        : `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/horse_racing_feed.php?token=d41d8cd98f00b204e9800998ecf8427e`;
    // if (selectedSportSpectate > 0) {
    //   link = `https://proxy.oddin.app/${selectedTournamentSpectate.replace(/&amp;/g, '&')}`
    // } else {
    //   link = `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/antepost_racing_feed.php?token=d41d8cd98f00b204e9800998ecf8427e`
    // }
    // } else if (selectedSportSpectate > -1) {
    //   link = `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/horse_racing_feed.php?token=d41d8cd98f00b204e9800998ecf8427e`
    // }

    props
      .fetchEvents(link)
      .then((resp) => {
        var xml = new XMLParser().parseFromString(resp);
        if (selectedSportSpectate > 0) {
          if (Array.isArray(xml?.children)) {
            let category = xml?.children[0];
            if (Array.isArray(category?.children)) {
              let totalEvents = [];
              let market = category.children.filter(
                (cat) => cat?.attributes?.name === "market"
              );
              if (Array.isArray(market[0]?.children)) {
                let tournament = market[0].children[0];
                if (Array.isArray(tournament?.children)) {
                  let events = tournament.children;
                  totalEvents = [...events];
                }
              }
              let outright = category.children.filter(
                (cat) => cat?.attributes?.name === "outright"
              );
              if (Array.isArray(outright[0]?.children)) {
                let outrightTournament = outright[0].children[0];
                if (Array.isArray(outrightTournament?.children)) {
                  let outrightEvents = outrightTournament.children;
                  totalEvents = [...totalEvents, ...outrightEvents];
                }
              }
              props.spectateSetData(totalEvents);
            }
          }
        } else {
          if (Array.isArray(xml?.children)) {
            let arrTemp = []; // eslint-disable-next-line
            xml.children.map((meeting) => {
              if (Array.isArray(meeting.children)) {
                let children = []; // eslint-disable-next-line
                meeting.children.map((race) => {
                  if (Array.isArray(race.children)) {
                    // eslint-disable-next-line
                    race.children.map((runner) => {
                      if (typeof runner.attributes === "object") {
                        runner.attributes = {
                          ...runner.attributes,
                          fractionPrice: runner.attributes?.odds,
                          price: runner.attributes?.oddsDecimal,
                          sport: "ANTEPOST_RACING",
                        };
                      }
                    });
                  }
                  if (typeof race.attributes === "object") {
                    children.push({
                      ...race,
                      attributes: {
                        ...race.attributes,
                        name: `${race.attributes?.name} ${race.attributes?.time}`,
                        children: race.children,
                      },
                    });
                  }
                });
                let obj = {
                  name: "event",
                  attributes: meeting.attributes,
                  children,
                };
                arrTemp.push(obj);
              }
            });
            props.spectateSetData(arrTemp);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (selectedSportSpectate > 0) {
      handleFetchSpectateCategories(selectedSportSpectate);
    }
  }, [selectedSportSpectate]);
  useEffect(() => {
    if (selectedCategorySpectate && selectedSportSpectate) {
      handleFetchSpectateTournaments(
        selectedSportSpectate,
        selectedCategorySpectate
      );
    } // eslint-disable-next-line
  }, [selectedCategorySpectate]);
  return (
    <form className="form-cust" onSubmit={handleSubmitFormSpectate}>
      <select
        className="top-input-drop"
        style={{ maxWidth: 150, marginRight: 15 }}
        onChange={(e) => {
          setSelectedSportSpectate(e.target.value);
          props.spectateSetSport(e.target.value);
        }}
        value={selectedSportSpectate}
      >
        <option value="" disabled selected>
          Select Sport
        </option>
        {[...sportsSpectate]
          .sort((a, b) => a.englishName.localeCompare(b.englishName))
          .map((sp, i) => (
            <option value={sp.id} className="bg-dark" key={`sport-${i}`}>
              {props.language === "en_US"
                ? sp.englishName
                : props.language === "en_CA"
                ? sp.canadianName
                : props.language === "sv_SE" || props.language === "sv_Mr_Green"
                ? sp.swedenName
                : props.language === "da_DK"
                ? sp.danishName
                : props.language === "ro_RO"
                ? sp.romanianName
                : props.language === "de_DE"
                ? sp.germanName
                : sp.spanishName}
            </option>
          ))}
      </select>
      {(selectedSportSpectate > 0 || selectedSportSpectate === null) && (
        <>
          <select
            className="top-input-drop"
            style={{ maxWidth: 150, marginRight: 15 }}
            onChange={(e) => {
              setSelectedCategorySpectate(e.target.value);
              props.spectateSetCategory(e.target.value);
            }}
            value={selectedCategorySpectate}
          >
            <option value="" disabled selected>
              Select Category
            </option>
            {[...spectateCategories]
              .sort((a, b) => a.description.localeCompare(b.description))
              .map((cat, i) => (
                <option value={cat.id} className="bg-dark" key={`cat-${i}`}>
                  {cat.description}
                </option>
              ))}
          </select>
          <select
            className="top-input-drop"
            style={{ maxWidth: 150, marginRight: 15 }}
            onChange={(e) => {
              setSelectedTournamentSpectate(e.target.value);
              props.spectateSetTournament(e.target.value);
            }}
            value={selectedTournamentSpectate}
          >
            <option value="" disabled selected>
              Select Tournament
            </option>
            {spectateTournaments.map((tournament, i) => (
              <option
                value={tournament.next_level}
                className="bg-dark"
                key={`tournament-${i}`}
              >
                {tournament.name}
              </option>
            ))}
          </select>
        </>
      )}
      <select
        className="top-input-drop"
        style={{ maxWidth: 150, marginRight: 15 }}
        onChange={(e) => props.spectateSetLanguage(e.target.value)}
        value={props.language}
      >
        <option value="en_US" className="bg-dark">
          English - UK
        </option>
        <option value="en_CA" className="bg-dark">
          English - Canada
        </option>
        <option value="de_DE" className="bg-dark">
          Germany
        </option>
        <option value="sv_SE" className="bg-dark">
          Sweden
        </option>
        <option value="es_ES" className="bg-dark">
          Spain
        </option>
        <option value="da_DK" className="bg-dark">
          Denmark
        </option>
        <option value="ro_RO" className="bg-dark">
          Romania
        </option>
        <option value="sv_Mr_Green" className="bg-dark">
          Sweden – Mr. Green
        </option>
      </select>
      {/* <div className="custom-control custom-switch">
        <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(e) => props.spectateSetBetType(e.target.checked)} />
        <label className="custom-control-label text-white" for="customSwitch1">Fractional</label>
      </div> */}
      <select
        className="top-input-drop"
        style={{ maxWidth: 150, marginRight: 15 }}
        onChange={(e) => props.spectateSetBetType(e.target.value)}
        value={props.betType}
      >
        <option value="decimal" className="bg-dark">
          Decimal
        </option>
        <option value="fractional" className="bg-dark">
          Fractional
        </option>
        <option value="american" className="bg-dark">
          American
        </option>
      </select>
      <button className="btn btn-primary top-btn" type={"submit"}>
        Submit
      </button>
    </form>
  );
};
const mapStateToProps = (state) => ({
  language: state.spectate.language,
  betType: state.spectate.betType,
});
const mapDispatchToProps = {
  fetchEvents,
  spectateSetData,
  spectateSetLanguage,
  spectateSetBetType,
  spectateSetCategory,
  spectateSetSport,
  spectateSetTournament,
};
export default connect(mapStateToProps, mapDispatchToProps)(SpectateForm);
