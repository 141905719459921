import Axios from 'axios'

const NETWORK_ERROR = 'NETWORK_ERROR'
export const betOffers = async leagueId => {
  try {
    return await Axios.get(
      `https://www.smart-feeds.com/getfeeds.aspx?Param=betoffer/group/${leagueId}&lang=es_ES`
    )
  } catch (err) {
    console.log(err)
    return NETWORK_ERROR
  }
}

export const eventOffers = async (eventId, lang) => {
  try {
    return await Axios.get(
      `https://www.smart-feeds.com/getfeeds.aspx?Param=betoffer/event/${eventId}&lang=${lang}
      `
    )
  } catch (err) {
    console.log(err)
    return NETWORK_ERROR
  }
}

export const fetchCategories = async (sport, ncid, lang) => {
  try {
    return await Axios.get(
      `https://eu-offering.kambicdn.org/offering/v2018/888es/category/pre_match_event/sport/${sport}?lang=${lang}&market=GB&client_id=2&channel_id=1&ncid=${ncid}`
    )
  } catch (err) {
    console.log(err)
    return NETWORK_ERROR
  }
}

export const fetchSports = async lang => {
  try {
    return await Axios.get(
      `https://eu-offering.kambicdn.org/offering/v2018/888es/event/live/open.json?lang=${lang}`
    )
  } catch (err) {
    return NETWORK_ERROR
  }
}

export const fetchSpectateCategories = async sportId => {
  try {
    return await Axios.get(
      `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/sports_feed.php?token=d41d8cd98f00b204e9800998ecf8427e&sportid=${sportId}`
    )
  } catch (err) {
    console.log(err)
    return NETWORK_ERROR
  }
}
export const fetchSpectateTournaments = async (sportId, catId) => {
  try {
    return await Axios.get(
      `https://proxy.oddin.app/https://spectate-externalfeeds.888sport.com/spectate/external/sports_feed.php?token=d41d8cd98f00b204e9800998ecf8427e&sportid=${sportId}&catid=${catId}`
    )
  } catch (err) {
    console.log(err)
    return NETWORK_ERROR
  }
}
export const fetchSpectateEvents = async link => {
  try {
    return await Axios.get(`${link}`)
  } catch (err) {
    console.log(err)
    return NETWORK_ERROR
  }
}
