import translations from "../constants/teamStatsTranslations";
export const generateTeamStatsHTML = (params) => {
  const language = params.language
  let banner = `<style>#team_stat_banner_${params.key}{display: block;overflow-x: auto;white-space: nowrap;font-family:Arial,Helvetica,sans-serif;border-collapse:collapse;width:100%;border:1px solid rgba(224,224,224,1)}#team_stat_banner_${params.key} tr{display: table-row; !important}#team_stat_banner_${params.key} td{display: table-cell !important;border-bottom:1px solid rgba(224,224,224,1);padding:16px;text-align:center}#team_stat_banner_${params.key} td:not(:last-child){border-right:none}#team_stat_banner_${params.key} td:first-child{text-align:left}#team_stat_banner_${params.key} tr:nth-child(6){background-color:rgba(0,0,0,.04)}#team_stat_banner_${params.key} tr:nth-child(9){background-color:rgba(0,0,0,.04)}#team_stat_banner_${params.key} th{padding:8px;padding-top:12px;padding-bottom:12px;text-align:center;background-color:#fd6301;color:#fff}#team_stat_banner_${params.key} td.colored-cell{background-color:rgba(0,0,0,.04)}.team-info-wrapper{margin-bottom:1rem; margin-top: 1rem; display:flex}.team-info{display:flex;justify-content:center;align-items:center}.column-3{flex:0 0 25%;max-width:25%}.column-4{flex:0 0 33.33333%;max-width:33.33333%} .team_stat_banner_title {font-size: 22px; text-align: center;}@media screen and (max-width: 600px) {#team_stat_banner_${params.key} td{font-size: 12px;padding: 10px 0px !important;white-space: normal;} #team_stat_banner_${params.key} th {font-size: 12px;padding: 10px 2px !important;} #team_stat_banner_${params.key} td:first-child {min-width: 30px;padding: 10px 0px 10px 5px !important;} .team_stat_banner_title {font-size: 18px;}}</style>`;
  banner += `<div><h3 class="team_stat_banner_title">${translations.seeLast[language]} ${params?.teamStats[0]?.team?.name} vs ${params?.teamStats[1]?.team?.name}</h3></div><div class="team-info-wrapper"><div class="column-3"></div>`;
  
  params?.teamStats?.map((teamStat) => {
    banner += `<div class="column-4 team-info"><b>${teamStat?.team?.name}</b></div>`;
  });
  //<img src="${teamStat?.team?.logo}" alt="${teamStat?.team?.name}" style="width:30px;height:30px;margin-left:10px">
  banner += `</div><table id="team_stat_banner_${params.key}"><tbody style="display: table; width: 100%"><tr><th></th><th>${translations.home[language]}</th><th>${translations.away[language]}</th><th>${translations.all[language]}</th><th>${translations.home[language]}</th><th>${translations.away[language]}</th><th>${translations.all[language]}</th></tr>`;
  params?.tableRows?.map((row) => {
    banner += `<tr><td>${row.category}</td><td>${row.first_home}</td><td>${row.first_away}</td><td class="colored-cell">${row.first_all}</td><td>${row.second_home}</td><td>${row.second_away}</td><td class="colored-cell">${row.second_all}</td></tr>`;
  });
  banner += "</tbody></table>";
  banner +=
    `<small style='margin-top: 5px; display: inline-block'>${translations.disclaimer[language]}</small>`;
  return banner;
};
