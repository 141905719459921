import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT
} from "./constants";
import { loginWithPassword, _handleLogout } from "./services";

/* Login actions */
export function loginSetLoading(loading) {
  return {
    type: LOGIN_LOADING,
    payload: loading,
  };
}

export function loginSetToken(token) {
  return {
    type: LOGIN_SUCCESS,
    payload: token,
  };
}

export function loginSetError(error) {
  return {
    type: LOGIN_ERROR,
    payload: error.message,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
export const checkLogin = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(loginSetLoading(true));
    loginWithPassword(formData).then(async (res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(loginSetLoading(false));
    })
  })
};

export const handleLogout = () => (dispatch) => {
  _handleLogout().then(async (res) => {
    await dispatch(logout());
  }).catch((err) => {
    console.log(err)
  })
};