import React, { useState } from "react";
import "./Bucket.style.css";
import $ from "jquery";
import { FaTrash, FaShoppingBasket, FaTimesCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { bucketDeleteItem, bucketUpdateSortingOrder } from "../../store/bucket/actions";

import { sortable } from 'react-sortable';

var odds = require("odds-converter");
const Item = (props) => {
	return (
		<li {...props} className="list-group-item">
			{props.children}
		</li>
	)
}
const Bucket = (props) => {
	var SortableItem = sortable(Item);
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => {
		$('.ba-we-love-subscribers').toggleClass("open");
		$('.img-fab.img').toggleClass("close");
		setIsOpen(!isOpen)
	}
	const getTotalBets = () => {
		let total = 1.0;
		if (props.apiType === "kambi") {
			props.items.map((item) => {
				return (
					total *= parseFloat(odds.american.toDecimal(item.oddsAmerican))
				);
			})
		} else {
			props.items.map((item) => {
				return total *= parseFloat(item.price);
			})
		}
		return total.toFixed(2);
	}
	const handleRemoveFromBucket = (item) => {
		props.bucketDeleteItem(item);
	}
	const onSortItems = (items) => {
		props.bucketUpdateSortingOrder(items)
	}
	const getOdd = (item) => {
		if (props.apiType === "kambi") {
			return parseFloat(odds.american.toDecimal(item.oddsAmerican)).toFixed(2);
		} else {
			return item.price;
		}
	}
	return (
		<div className="ba-we-love-subscribers-wrap">
			<div className="ba-we-love-subscribers popup-ani">
				<header className="shadow-sm">
					<h1>Combo Bets Bucket</h1>
				</header>
				<ul className="list-group" style={{ textAlign: "left" }}>
					{props.items.length === 0 && (
						<li className="list-group-item d-flex justify-content-center  align-items-center" >No bets found in the bucket!</li>
					)}
					{props.items.map((item, i) => (
						<SortableItem
							key={i}
							onSortItems={onSortItems}
							items={props.items}
							sortId={i}>
							{item.eventName} <br /> {item.title} <br /> {item.betName}
							<span className="badge badge-secondary badge-pill" style={{ margin: "0px 20px" }}>		{getOdd(item)}
							</span>
							<FaTrash style={{ cursor: "pointer" }} onClick={() => handleRemoveFromBucket(item)} />
						</SortableItem>
					))}
				</ul>
				{(props.items?.length > 0) && (
					<div className="row mt-3 d-flex justify-content-center align-items-center">
						<div className="col-4"><strong>Total:</strong> {getTotalBets()}</div>
						<div className="col-8"><button className="btn btn-primary" data-toggle="modal"
							data-target="#svgComboModal" onClick={() => handleToggle()}>Generate Banner</button></div>
					</div>
				)}
				<div className="img ba-logo logo-ani"></div>
			</div>
			<div
				className="ba-we-love-subscribers-fab shadow-lg"
				onClick={() => handleToggle()}
				style={{ background: "#FF6700" }}
			>
				<div className="wrap" style={{ position: "relative" }}>
					{(props.items?.length > 0) && (
						<span className="badge badge-light badge-pill p-2" style={{ position: "absolute", top: -12, right: -5 }}>{props.items.length}</span>
					)}
					{isOpen ? <FaTimesCircle style={{ position: "absolute", height: 45, width: 45, top: 10, left: 11 }} color={"#fff"} /> : <FaShoppingBasket style={{ position: "absolute", height: 45, width: 45, top: 7, left: 11 }} color={"#fff"} />}
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({
	items: state.bucket.items,
	apiType: state.bucket.apiType
});
const mapDispatchToProps = {
	bucketDeleteItem,
	bucketUpdateSortingOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(Bucket);