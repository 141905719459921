import {
  PREDICTION_BANNER_LOADING,
  PREDICTION_BANNER_SET_DATA,
  PREDICTION_BANNER_SET_LANGUAGE,
  PREDICTION_BANNER_SET_DETAIL,
} from "./constants";

const initialState = {
  predictionBanners: [],
  loading: false,
  language: "en",
  predictionBanner: null,
};

export function predictionBannerReducer(state = initialState, action) {
  switch (action.type) {
    case PREDICTION_BANNER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PREDICTION_BANNER_SET_DATA:
      return {
        ...state,
        predictionBanners: action.payload,
      };
    case PREDICTION_BANNER_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case PREDICTION_BANNER_SET_DETAIL:
      return {
        ...state,
        predictionBanner: action.payload,
      };
    default:
      return state;
  }
}
