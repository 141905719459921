import React from "react";
import PredictionsHeader from "../../components/predictionBanner/Header";
import PredictionsTable from "../../components/predictionBanner/PredictionsTable";
import CreatePredictionsModal from "../../components/predictionBanner/CreatePredictionsModal";
const PredictionsGenerator = () => {
  return (
    <div className="mainWrapper">
      <div className="container-fluid">
        <PredictionsHeader />
        <PredictionsTable />
        <CreatePredictionsModal />
      </div>
    </div>
  );
};
export default PredictionsGenerator;
