import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import $ from "jquery";
import deDETranslation from "../../locales/de_DE";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  createTeamStatsBanner,
  updateTeamStatsBanner,
} from "../../store/teamStats/actions";

//services
import { fetchFootballAPIInfo } from "../../store/teamStats/services";

const initialState = {
  leagueId: "",
  leagueName: "",
  firstTeamId: "",
  firstTeamName: "",
  secondTeamId: "",
  secondTeamName: "",
  country: "",
  season: new Date().getFullYear(),
};
const useCreateTeamStatistic = () => {
  const dispatch = useDispatch();
  const { teamStat, language } = useSelector((state) => state.teamStats);
  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [countries, setCountries] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const handleSetData = (e) => {
    const name = e.target.name,
      value = e.target.value;

    if (name === "country") {
      //reset other dropdowns if country gets changed
      setState({
        ...initialState,
        country: value,
      });
    } else if (name === "leagueId") {
      let leagueName = state.leagueName,
        season = state.season;
      //get the name of the selected league
      if (name === "leagueId") {
        let selectedLeague = leagues.filter((l) => l.value == value);
        if (selectedLeague.length) {
          selectedLeague = selectedLeague[0];
          if (Array.isArray(selectedLeague.seasons)) {
            season =
              selectedLeague.seasons[selectedLeague.seasons.length - 1].year;
          }
          leagueName = selectedLeague.label;
        }
      }
      setState({
        ...initialState,
        leagueId: value,
        leagueName,
        country: state.country,
        season,
      });
    } else if (["firstTeamId", "secondTeamId"].includes(name)) {
      const isFirstTeam = name === "firstTeamId";
      let teamName = isFirstTeam ? state.firstTeamName : state.secondTeamName;
      //get the name of the selected team
      const selectedTeam = teams.filter((t) => t.value == value);
      if (selectedTeam.length) {
        teamName = selectedTeam[0].label;
      }
      setState({
        ...state,
        [name]: value,
        [isFirstTeam ? "firstTeamName" : "secondTeamName"]: teamName,
      });
    }
  };
  const handleFetchLeagues = async (_country) => {
    try {
      const resp = await fetchFootballAPIInfo(
        "leagues",
        _country !== "undefined"
          ? {
              code: _country,
            }
          : null
      );
      if (Array.isArray(resp?.response)) {
        setLeagues(
          resp.response.map((league) => ({
            value: league.league?.id,
            label: league.league?.name,
            seasons: league.seasons,
          }))
        );
      }
    } catch (err) {}
  };
  const handleFetchCountries = async () => {
    try {
      const resp = await fetchFootballAPIInfo("countries");
      if (Array.isArray(resp?.response)) {
        setCountries(
          resp.response.map((country) => ({
            value: country?.code ?? "undefined",
            label: country?.name,
          }))
        );
      }
    } catch (err) {}
  };
  const handleFetchTeams = async (leagueId, season) => {
    try {
      const resp = await fetchFootballAPIInfo("teams", {
        league: leagueId,
        season,
      });
      if (Array.isArray(resp?.response)) {
        setTeams(
          resp.response.map((team) => {
            let teamLabel = team.team?.name;
            if (language === "de") {
              teamLabel = deDETranslation[team.team?.name] ?? team.team?.name;
            }
            return {
              value: team.team?.id,
              label: teamLabel,
            };
          })
        );
      }
    } catch (err) {}
  };
  const handleSaveBanner = async (e) => {
    try {
      e.preventDefault();
      setBtnLoading(true);
      if (teamStat) {
        await dispatch(
          updateTeamStatsBanner({
            ...state,
            key: teamStat.key,
          })
        );
      } else {
        await dispatch(createTeamStatsBanner(state));
      }
      $("#createTeamStatisticModal").modal("hide");
      $(".modal-backdrop").remove();
      setState(initialState);
      toast.success(
        `Banner ${teamStat !== null ? "updated" : "created"} successfully`
      );
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setBtnLoading(false);
    }
  };
  useEffect(() => {
    handleFetchCountries();
  }, []);
  useEffect(() => {
    if (state.country) {
      handleFetchLeagues(state.country);
    }
  }, [state.country]);
  useEffect(() => {
    if (state.leagueId) {
      handleFetchTeams(state.leagueId, state.season);
    }
  }, [state.leagueId, language]);
  useEffect(() => {
    if (teamStat) {
      setState(teamStat);
    } else {
      setState(initialState);
    }
  }, [teamStat]);
  return {
    countries,
    leagues,
    teams,
    state,
    btnLoading,
    handleSetData,
    handleFetchLeagues,
    handleSaveBanner,
  };
};
export default useCreateTeamStatistic;
