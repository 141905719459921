import { useState, useEffect } from "react"
import { toast } from "react-toastify";
import $ from "jquery";
import dayjs from "dayjs"
//redux
import { useDispatch, useSelector } from "react-redux"
import { createPredictionBanner, updatePredictionBanner } from "../../store/predictionBanners/actions"

//services
import { fetchFootballAPIInfo } from "../../store/predictionBanners/services"

const initialState = {
	leagueId: "",
	leagueName: "",
	firstTeamId: "",
	firstTeamName: "",
	secondTeamId: "",
	secondTeamName: "",
	country: "",
	fixtureId: "",
	season: new Date().getFullYear() - 1
}
const useCreatePredictionBanner = () => {
	const dispatch = useDispatch()
	const {predictionBanner} = useSelector((state) => state.predictionBanners)
	const [leagues, setLeagues] = useState([])
	const [countries, setCountries] = useState([])
	const [btnLoading, setBtnLoading] = useState(false);
	const [state, setState] = useState(initialState)
	const handleSetData = (e, fixtures = []) => {
		const name = e.target.name,
			value = e.target.value;

		if (name === "country") { //reset other dropdowns if country gets changed
			setState({
				...initialState,
				country: value
			})
		} else if (name === "leagueId") {
			let leagueName = state.leagueName,
				season = state.season;
			//get the name of the selected league
			if (name === "leagueId") {
				let selectedLeague = leagues.filter(l => l.value == value)
				if (selectedLeague.length) {
					selectedLeague = selectedLeague[0]
					if(Array.isArray(selectedLeague.seasons)){
						season = selectedLeague.seasons[selectedLeague.seasons.length - 1].year
					}
					leagueName = selectedLeague.label
				}
			}
			setState({
				...initialState,
				leagueId: value,
				leagueName,
				country: state.country,
				season
			})
		} else if (name === "fixtureId") {
			const selectedFixture = fixtures.find(fix => fix.value == value)
			if(selectedFixture){
				const _teams = selectedFixture.label?.split("VS")
				setState({...state,
					firstTeamName: _teams[0]?.trim(),
					secondTeamName: _teams[1]?.trim(),
					fixtureId: value
				})
			}else{
				setState({
					...state,
					fixtureId: value
				})
			}
		}
	}
	const handleFetchLeagues = async (_country) => {
		try {
			const resp = await fetchFootballAPIInfo("leagues", _country !== "undefined" ? {
				code: _country,
				current: true,
				season: state.season
			} : null)
			if (Array.isArray(resp?.response)) {
				setLeagues(resp.response.filter(({seasons}) => seasons.find(season => dayjs(season.end, "YYYY-MM-DD").isAfter()) ).map(league => ({
					value: league.league?.id,
					label: league.league?.name,
					seasons: league.seasons
				})))
			}
		} catch (err) {

		}
	}
	const handleFetchCountries = async () => {
		try {
			const resp = await fetchFootballAPIInfo("countries")
			if (Array.isArray(resp?.response)) {
				setCountries(resp.response.map(country => ({
					value: country?.code ?? "undefined",
					label: country?.name
				})))
			}
		} catch (err) {

		}
	}
	// const handleFetchTeams = async (leagueId, season) => {
	// 	try {
	// 		const resp = await fetchFootballAPIInfo("teams", {
	// 			league: leagueId,
	// 			season
	// 		})
	// 		if (Array.isArray(resp?.response)) {
	// 			setTeams(resp.response.map(team => ({
	// 				value: team.team?.id,
	// 				label: team.team?.name
	// 			})))
	// 		}
	// 	} catch (err) {
	// 	}
	// }
	const handleSaveBanner = async (e) => {
		try {
			e.preventDefault();
			setBtnLoading(true);
			if(predictionBanner){
				await dispatch(updatePredictionBanner({
					...state, key: 
					predictionBanner.key
				}))
			}else{
				await dispatch(createPredictionBanner(state));
			}
			$("#createPredictionsModal").modal("hide");
			$('.modal-backdrop').remove();
			setState(initialState)
			toast.success(`Banner ${predictionBanner !== null ? "updated" : "created"} successfully`)
		} catch (err) {
			toast.error("Something went wrong")
		} finally {
			setBtnLoading(false);
		}
	}
	useEffect(() => {
		handleFetchCountries()
	}, [])
	useEffect(() => {
		if (state.country) {
			handleFetchLeagues(state.country)
		}
	}, [state.country])
	// useEffect(() => {
	// 	if (state.leagueId) {
	// 		handleFetchTeams(state.leagueId, state.season)
	// 	}
	// }, [state.leagueId])
	useEffect(()=>{
		if(predictionBanner){
			setState(predictionBanner)
		}else{
			setState(initialState)
		}
	}, [predictionBanner])
	return {
		countries,
		leagues,
		state,
		btnLoading,
		handleSetData,
		handleFetchLeagues,
		handleSaveBanner
	}
}
export default useCreatePredictionBanner