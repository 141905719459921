import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Loader from "../Loader";
import InitialMessage from "../Message";
import SpectateBetCard from "./SpectateBetCard";
import GetMarketName from "../../utils/GetMarketName";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "90%",
  },
  headText: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
    borderColor: "#fff",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabRow: {
    width: "90%",
    backgroundColor: "#222",
    borderColor: "#0000",
  },
  tabRowInner: {
    borderColor: "#0000",
  },
}));

const SpectateTable = (props) => {
  const classes = useStyles();
  const [accordian, setAccordian] = useState(null);
  return (
    <TableContainer component={Paper} className="bg-dark">
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {props.loading ? (
            <TableRow
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
                width: "70vw",
                margin: "auto",
              }}
            >
              <TableCell
                colSpan={3}
                scope="row"
                className={classes.tabRowInner}
              >
                <Loader />
              </TableCell>
            </TableRow>
          ) : !props.events.length ? (
            <InitialMessage />
          ) : (
            props.events.map((event, i) => {
              return (
                <TableRow className={classes.tabRow} key={i}>
                  <TableCell
                    colSpan={3}
                    scope="row"
                    className={classes.tabRowInner}
                  >
                    <div className="accordion-item">
                      <button
                        className="accordion-button btn btn-dark btn-accordian px-4 py-2 collapsed w-100"
                        type="button"
                        data-bs-target={`#flush-collapse${i}`}
                        onClick={() => {
                          setAccordian(accordian === i ? null : i);
                        }}
                      >
                        <div className="row">
                          <div className="col-10">
                            <div className="row btn-colasable">
                              <h5 className="col-12 text-left">
                                {GetMarketName(
                                  event?.attributes?.name,
                                  props.lang
                                )}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </button>
                      {accordian === i && (
                        <div
                          id={`flush-collapse${i}`}
                          className="accordion-collapse w-100 bg-white p-2"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          {event.children.map((val, index) => (
                            <SpectateBetCard
                              length={val.children?.length}
                              betOffer={
                                Array.isArray(val.children) ? val.children : []
                              }
                              market={val.attributes}
                              event={event.attributes}
                              key={index}
                              setSelectedBet={(value) =>
                                props.setSelectedBet(value)
                              }
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const mapStateToProps = (state) => ({
  loading: state.spectate.loading,
  events: state.spectate.events,
  lang: state.spectate.language,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SpectateTable);
