const roROTranslation = {
  'Correct Score': 'Scor Corect',
  'Full Time Result': 'Rezultat Final',
  '1st Half - 3-Way': 'Prima Repriză - 1, X, 2 ',
  'Double Chance': 'Șansă Dublă',
  'Both Teams to Score': 'Ambele Înscriu',
  'Draw No Bet': 'Draw No Bet',
  Handicap: 'Handicap',
  '2nd Half 3-Way': 'A Doua Repriză - 1, X, 2 ',
  '1st Half - Both Teams to Score': 'Prima Repriză - Ambele Înscriu',
  'First Goalscorer': 'Primul Marcator',
  'Anytime Goalscorer': 'Înscrie Oricând',
  'Highest Scoring Half': 'Repriza cu cele mai multe goluri',
  'Last Goalscorer': 'Ultimul marcator',
  'Asian Handicap': 'Handicap Asiatic',
  'Score in Both Halves': 'Înscrie în Ambele Rperize',
  'Win Both Halves': 'Câștigă Ambele Reprize',
  'Win Either Half': 'Câștigă Oricare Repriză',
  'Odd or Even Total': 'Par sau Impar Total',
  'To Win To Nil': 'Câștigă la zero',
  'Clean Sheet': 'Fără gol primit',
  'Match Result and Both Teams To Score': 'Rezultat Final și Ambele Înscriu',
  'Half-time Odd or Even Total': 'Prima Repriză Total Par sau Impar',
  'Total Goals Over/Under': 'Total Goluri Peste/Sub',
  'Half-time Correct Score': 'Scor Corect - Prima Repriză',
  'Match Result and Total Goals Over/Under':
    'Rezultat Final și Total Goluri Peste/Sub',
  'Half-time Handicap': 'Handicap Prima Repriză',
  'Hat-trick': 'Hattrick',
  'Away Team Total Goals Over/Under': 'Echipa Oaspete Total Goluri Peste/Sub',
  'Half-time Totals Over/Under': 'Prima Repriză Total Goluri Peste/Sub',
  'Home Team Total Goals Over/Under': 'Echipa Gazdă Total Goluri Peste/Sub',
  'Half-time Draw No Bet': 'Prima Repriză Draw No Bet',
  '2nd Half Correct Score': 'Scor Corect - Repriza a Doua',
  'Half-time/Full-time': 'Pauză/Final',
  'Total Corners Over/Under': 'Total Cornere Peste/Sub',
  'Booking Points Over/Under': 'Puncte Cartonașe Peste/Sub',
  BetBuilder: 'Creează-ți pariul',
  MatchCombo: 'MatchCombo',
  'Home Team Total Corners Over/Under': 'Echipa Gazdă Total Cornere Peste/Sub',
  'Away Team Total Corners Over/Under':
    'Echipa Oaspete Total Cornere Peste/Sub',
  'Total Corners 1st Half Over/Under': 'Total Cornere Prima Repriză Peste/Sub',
  'Total Corners 2nd Half Over/Under': 'Total Cornere A Doua Repriză Peste/Sub',
  'Home Team Booking Points Over/Under':
    'Echipa Gazdă Puncte Cartonașe Peste/Sub',
  'Away Team Booking Points Over/Under':
    'Echipa Oaspete Puncte Cartonașe Peste/Sub',
  'Most Corners': 'Cele mai multe cornere',
  '1st Half - Most Corners': 'Prima Repriză - Cele mai multe cornere',
  'Player To Be Carded': 'Jucătorul să primească un Cartonaș',
  'Player To Be Sent Off': 'Jucătorul să fie eliminat',
  'Player to Have One or More Assists':
    'Jucătorul să ofere una sau mai multe Pase de Gol',
  'Player to Have Two or More Assists':
    'Jucătorul să ofere două sau mai multe Pase de Gol',
  'Player to Have Three or More Assists':
    'Jucătorul să ofere trei sau mai multe Pase de Gol',
  'Player to Score from Outside the Box':
    'Jucătorul să Înscrie din afara Careului',
  'Player to Score a Header': 'Jucătorul să înscrie cu capul',
  '1st Half - Player to Score from Outside the Box':
    'Prima Repriză - Jucătorul să Înscrie din afara Careului',
  '1st Half - Player to Score a Header':
    'Prima repriză - Jucătorul să Înscrie cu Capul',
  'Total shots at goal': 'Total șuturi la poartă',
  'Total shots on target': 'Total șuturi pe poartă',
  'Total passes': 'Total pase',
  'Home Team Total Shots at Goal': 'Echipa Gazdă Total Șuturi la Poartă',
  'Away Team Total Shots at Goal': 'Echipa Oaspete Total Șuturi la Poartă',
  'Home Team Total Shots on Target': 'Echipa Gazdă Total Șuturi pe Poartă',
  'Away Team Total Shots on Target': 'Echipa Oaspete Total Șuturi pe Poartă',
  'Home Team Total Passes': 'Echipa Gazdă Total Pase',
  'Away Team Total Passes': 'Echipa Oaspete Total Pase',
  oddsTerms:
    '*Cotele afișate pot suferi modificări. Se aplică miza maximă. Terminii și condiții se aplică.',
  'Outright Winner': 'Câștigător',
  'To Finish In Top 4': 'Loc în Top 4',
  'To Be Relegated': 'Retrogradare',
  'Player Top Goalscorer': 'Golgheter',
  "Bet Now": "PARIU ACUM!",
  "Bet Expired": "Pariu Expirat",
  "Strength": "Putere",
  "Attacking Potential": "Potențial Ofensiv",
  "Defensive Potential": "Potențial Defensiv",
  "Poisson Distribussion": "Distribuție Poisson",
  "Strength H2H": "Putere H2H",
  "Goals H2H": "Goluri H2H",
  "Wins the Game": "Caștigă Meciul"
}

export default roROTranslation
