import {
  SPECTATE_LOADING,
  SPECTATE_SET_DATA,
  SPECTATE_SET_LANGUAGE,
  SPECTATE_SET_BET_TYPE,
  SPECTATE_SET_SPORT,
  SPECTATE_SET_CATEGORY,
  SPECTATE_SET_TOURNAMENT
} from "./constants";

const initialState = {
  sport: {},
  category: {},
  tournament: {},
  events: [],
  loading: false,
  language: "en_US",
  betType: "decimal"
};

export function spectateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SPECTATE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SPECTATE_SET_DATA:
      return {
        ...state,
        events: action.payload
      };
    case SPECTATE_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case SPECTATE_SET_BET_TYPE:
      return {
        ...state,
        betType: action.payload
      }
    case SPECTATE_SET_SPORT:
      return {
        ...state,
        sport: action.payload
      };
    case SPECTATE_SET_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case SPECTATE_SET_TOURNAMENT:
      return {
        ...state,
        tournament: action.payload
      };
    default:
      return state;
  }
}
