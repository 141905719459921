import React from "react";
//redux
import { connect } from "react-redux";
import { netWorthSetLanguage } from "../../store/netWorth/actions";
//constants
import languages from "../../constants/languages";
//components
import SelectPicker from "../common/formFields/SelectPicker";
import Button from "../common/formFields/Button";

const Header = (props) => {
	return (
		<div className='row'>
			<div className='col-md-12 my-5'>
				<div className='col-md-12 bg-dark shadow-sm rounded'>
					<form className='form-cust' style={{ justifyContent: "space-between" }}>
						<SelectPicker 
							label={""}
							value={props.language}
							className="top-input-drop"
							onChange={e => props.netWorthSetLanguage(e.target.value)}
							options={languages}
						/>
						<Button 
							label="Create New Banner"
							type="button"
							className='btn btn-primary top-btn'
							data-toggle="modal"
							data-target="#createNetWorthModal"
						/>
					</form>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({
	language: state.netWorth.language
});
const mapDispatchToProps = {
	netWorthSetLanguage
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);