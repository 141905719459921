import React, {useEffect} from "react";
import Header from "../../components/NetWorthGenerator/Header";
import NetWorthTable from "../../components/NetWorthGenerator/NetWorthTable";
import CreateNetWorthModal from "../../components/NetWorthGenerator/CreateNetWorthModal";
import ShowNetWorthModal from "../../components/NetWorthGenerator/ShowNetWorthModal";
import EditNetWorthModal from "../../components/NetWorthGenerator/EditNetWorthModal";

//redux
import { connect } from "react-redux";
import { fetchNetWorths } from "../../store/netWorth/actions";
const NetWorthGenerator = (props) => {
	useEffect(()=>{
		props.fetchNetWorths();
	}, [])  // eslint-disable-line
	return (
		<div className='mainWrapper' >
			<div className='container-fluid'>
				<Header />
				<NetWorthTable />
			</div>
			<CreateNetWorthModal />
			<ShowNetWorthModal />
			<EditNetWorthModal />
		</div>
	)
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
	fetchNetWorths
};
export default connect(mapStateToProps, mapDispatchToProps)(NetWorthGenerator);