import firebase from "../../firebase/config";
export function _fetchNetWorths() {
  return new Promise(async (resolve, reject) => {
    try {
      let snapshot = await firebase.firestore()
        .collection("NetWorths")
        .get();
      let list = [];
      snapshot.forEach((documentSnapshot) => {
        list.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        })
      });
      resolve(list);
    } catch (err) {
      reject(err)
    }
  });
}
export function _createNetWorth(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.firestore()
        .collection("NetWorths")
        .add({
          name: formData.name,
          yearlyIncome: formData.yearlyIncome,
          createdOn: Date.now()
        })
        resolve(true);
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
}
export function _deleteNetWorth(key) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.firestore()
        .collection("NetWorths")
        .doc(key).delete()
        resolve(true);
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
}
export function _updateNetWorth(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.firestore()
        .collection("NetWorths")
        .doc(formData.key)
        .update({name: formData.name, yearlyIncome: formData.yearlyIncome})
      resolve(true);
    } catch (err) {
      console.log(err);
      reject(err)
    }
  });
}