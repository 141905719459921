import React from "react";
import { Switch, HashRouter } from "react-router-dom";
import RouteWithLayout from "./components/routeWithLayout/RouteWithLayout";
import MinimalView from "./layout/minimal/MinimalView";
// import MainView from "./layout/main/MainView";
import AdminView from "./layout/adminView/AdminView";
// imported Screens
import Login from "./screens/auth/login/Login";
// admin
import Dashboard from "./screens/dashboard/Dashboard";
import NetWorthGenerator from "./screens/netWorthGenerator/NetWorthGenerator";
import TeamStatisticGenerator from "./screens/teamStatisticGenerator/TeamStatisticGenerator";
import PredictionsGenerator from "./screens/predictionsGenerator/PredictionsGenerator";
// import supportPage from "./screens/supportPage/supportPage";

const Routes = () => {
	return (
		<HashRouter>
			<Switch>
				<RouteWithLayout
					exact
					path="/"
					component={Login}
					layout={MinimalView}
					access="public"
					restricted={true}
				/>
				<RouteWithLayout
					exact
					path="/login"
					component={Login}
					layout={MinimalView}
					access="public"
					restricted={true}
				/>
				<RouteWithLayout
					exact
					path="/bet-generator"
					component={Dashboard}
					layout={AdminView}
					access="private"
				/>
				<RouteWithLayout
					exact
					path="/net-worth-generator"
					component={NetWorthGenerator}
					layout={AdminView}
					access="private"
				/>
				<RouteWithLayout
					exact
					path="/team-statistics-generator"
					component={TeamStatisticGenerator}
					layout={AdminView}
					access="private"
				/>
				<RouteWithLayout
					exact
					path="/predictions-generator"
					component={PredictionsGenerator}
					layout={AdminView}
					access="private"
				/>
				{/* <RouteWithLayout
					exact
					path="/support"
					component={supportPage} 
					layout={AdminView}
					access="private"
				/> */}
			</Switch>
		</HashRouter>
	);
};
export default Routes;
