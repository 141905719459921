const translations = {
    perYear: {
        en: "Per year",
        es: "Al año",
        de: "Pro Jahr",
        it: "All'anno",
        da: "Pr. år",
        sv: "Per år",
    },
    perMonth: {
        en: "Per month",
        es: "Al mes",
        de: "Pro Monat",
        it: "Al mese",
        da: "Pr. måned",
        sv: "Per månad",
    },
    perWeek: {
        en: "Per week",
        es: "A la semana",
        de: "Pro Woche",
        it: "Per ciascuna settimana",
        da: "Pr. uge",
        sv: "Per vecka",
    },
    perDay: {
        en: "Per day",
        es: "Al día",
        de: "Pro Tag",
        it: "Al giorno",
        da: "Pr. dag",
        sv: "Per dag",
    },
    perHour: {
        en: "Per hour",
        es: "A la hora",
        de: "Pro Stunde",
        it: "All'ora",
        da: "Pr. time",
        sv: "Per timme",
    },
    perMinute: {
        en: "Per minute",
        es: "Al minuto",
        de: "Pro Minute",
        it: "Al minuto",
        da: "Pr. minut",
        sv: "Per minut",
    },
    textPart1: {
        en: "Since you've been viewing this page, ",
        es: "Desde que has entrado en esta página, ",
        de: "Seit Sie diese Seite aufgerufen haben, hat  ",
        it: "Dal momento in cui hai iniziato a leggere questo contenuto, ",
        da: "I løbet af den tid, du har været på denne side, har ",
        sv: "Sedan du började titta på denna sida har",
    },
    textPart2: {
        en: "has earned ",
        es: "ha ganado ",
        de: "verdient ",
        it: "ha incassato ",
        da: "tjent ",
        sv: "tjänat ",
    }
}
export default translations;