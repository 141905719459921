const daDKTranslation = {
  '3-Way': 'RESULTAT',
  'Both Teams to Score': 'BEGGE HOLD SCORE',
  'Draw No Bet': 'UAFGJORT INGEN SPIL',
  'Total Goals Over/Under': 'TOTALT ANTAL MÅL, OVER/UNDER',
  'Double Chance': 'DOBBELTCHANCE',
  'Correct Score': 'KORREKT SCORE',
  '1st Half - 3-Way': '1. HALVLEG RESULTAT',
  '2nd Half - 3-Way': '2. HALVLEG RESULTAT',
  '1st Half - Both Teams to Score': '1. HALVLEG BEGGE HOLD SCORER',
  'First Goalscorer': 'FØRSTE MÅLSCORER',
  'Anytime Goalscorer': 'MÅLSCORER',
  'Highest Scoring Half': 'HALVLEG MED HØJESTE SCORE',
  'Last Goalscorer': 'SIDSTE MÅLSCORER',
  'Score in Both Halves': 'SCORER I BEGGE HALVLEGE',
  'Win Both Halves': 'VINDER BEGGE HALVLEGR',
  'Win Either Half': 'VINDER ÈN AF HALVLEGENE',
  'Odd or Even Total': 'ULIGE ELLER LIGE I ALT',
  'To Win to Nil': 'VINDER TIL NUL',
  'Match Result and Both Team to Score':
    'KAMPENS RESULTAT OG BEGGE HOLD SCORER',
  'Half-time Odd or Even Total': '1. HALVLEG - ULIGE ELLER LIGE',
  'Half-time Correct Score': '1. HALVLEG - KORREKT SCORE',
  'Match Result and Total Goals Over/Under':
    'KAMPENS RESULTAT OG MÅL I ALT OVER/UNDER',
  'Half-time Double Chance': '1. HALVLEG - DOBBELTCHANCE',
  'Half-time Handicap': '1. HALVLEG - HANDICAP',
  'Away Team Total Goals Over/Under': 'UDEHOLD MÅL I ALT OVER/UNDER',
  'Half-time Totals Over/Under': 'HALVLEG MÅL I ALT OVER/UNDER',
  'Home Team Total Goals Over/Under': 'HJEMMEHOLD MÅL I ALT OVER/UNDER',
  'Half-time Draw No Bet': '1. HALVLEG - PENGENE TILBAGE VED UAFGJORT',
  '2nd Half Correct Score': '2. HALVLEG - KORREKT SCORE',
  'Half-time/Full-time': 'HALVLEG/FULDTID',
  'Total Corners Over/Under': 'HJØRNESPARK I ALT OVER/UNDER',
  'Booking Points Over/Under': 'KORTPOINT OVER/UNDER',
  'Home Team Total Corners Over/Under':
    'HJEMMEHOLD HJØRNESPARK I ALT OVER/UNDER',
  'Away Team Total Corners Over/Under': 'UDEHOLD HJØRNESPARK I ALT OVER/UNDER',
  'Total Corners 1st Half Over/Under':
    '1. HALVLEG - HJØRNESPARK I ALT OVER/UNDER',
  'Total Corners 2nd Half Over/Under':
    '2. HALVLEG - HJØRNESPARK I ALT OVER/UNDER',
  'Home Team Booking Points Over/Under': 'HJEMMEHOLD KORTPOINT OVER/UNDER',
  'Away Team Booking Points Over/Under': 'UDEHOLD KORTPOINT OVER/UNDER',
  'Most Corners': 'FLEST HJØRNESPARK',
  '1st Half - Most Corners': '1. HALVLEG - FLEST HJØRNESPARK',
  'Player To Be Carded': 'SPILLER FÅR ADVARSEL',
  'Player To Be Sent Off': 'SPILLER BLIVER UDVIST',
  'Player to Score from Outside the Box': 'SPILLER SCORER UDEN FOR FELTET',
  'Player to Score a Free Kick': 'SPILLER SCORER PÅ FRISPARK',
  'Player to Score a Penalty': 'SPILLER SCORER PÅ STRAFFESPARK',
  'Player to Score a Header': 'SPILLER SCORER HOVEDSTØDSMÅL',
  '1st Half - Player to Score from Outside the Box':
    'HALVLEG - SPILLER SCORER UDEN FOR FELTET',
  '1st Half - Player to Score a Free Kick':
    '1. HALVLEG - SPILLER SCORER PÅ FRISPARK',
  '1st Half - Player to Score a Penalty':
    '1. HALVLEG - SPILLER SCORER PÅ STRAFFESPARK',
  '1st Half - Player to Score a Header':
    '1. HALVLEG - SPILLER SCORER HOVEDSTØDSMÅL',
  'England v Senegal': 'England vs. Senegal',
  'France v Poland': 'Frankrig vs. Polen',
  'Netherlands v Argentina': 'Hollands vs. Argentina',
  'Argentina v Australia': 'Argentina vs. Australien',
  'Japan v Croatia': 'Japan vs. Kroatien',
  'Morocco v Spain': 'Marokko vs. Spanien',
  'Brasil v South Korea': 'Brasilien vs. Sydkorea',
  Poland: 'Polen',
  Argentina: 'Argentina',
  'Saudi Arabia': 'Saudi-Arabien',
  Mexico: 'Mexico',
  Tunisia: 'Tunesien',
  France: 'Frankrig',
  Canada: 'Canada',
  morocco: 'Marokko',
  Croatia: 'Kroatien',
  Belgium: 'Belgien',
  Japan: 'Japan',
  Spain: 'Spanien',
  Brazil: 'Brasilien',
  Cameroon: 'Cameroon',
  Ghana: 'Ghana',
  Uruguay: 'Uruguay',
  Serbia: 'Serbien',
  Switzerland: 'Schweiz',
  'South Korea': 'Sydkorea',
  Portugal: 'Portugal',
  Australia: 'Australien',
  Denmark: 'Danmark',
  'Costa Rica': 'Costa Rica',
  Germany: 'Tyskland',
  England: 'England',
  Senegal: 'Senegal',
  Netherlands: 'Holland',
  USA: 'USA',
  "Outright Winner": "Vinder",
  "To Finish In Top 4": "Top 4",
  "To Be Relegated": "Nedrykning",
  "Player Top Goalscorer": "Topscorer",
  "Bet Now": "SPIL!",
  "Bet Expired": "EXPIRED"
}

export default daDKTranslation
