import React, { useEffect } from "react";
import $ from "jquery";
//components
import SelectPicker from "../common/formFields/SelectPicker";
import Button from "../common/formFields/Button";
import ModalWrapper from "../common/ModalWrapper";
import TeamStatisticBanner from "./TeamStatisticBanner";
import { toast } from "react-toastify";

//hooks
import useCreateTeamStatistic from "../../hooks/teamStats/useCreateTeamStatistic";
import useGenerateTeamStatsBanner from "../../hooks/teamStats/useGenerateTeamStatsBanner";

//redux
import { useDispatch, useSelector } from "react-redux";
import { teamStatsSetDetail } from "../../store/teamStats/actions";

//util
import { generateTeamStatsHTML } from "../../utils/GenerateTeamStatsHTML";

const CreateTeamStatisticModal = (props) => {
	const dispatch = useDispatch()
	const {teamStat, language} = useSelector((state) => state.teamStats)
	const {
		countries,
		leagues,
		teams,
		state,
		btnLoading,
		handleSetData,
		handleSaveBanner
	} = useCreateTeamStatistic()

	const { teamStats, tableRows } = useGenerateTeamStatsBanner(state)

	const handleCopyBanner = () => {
		navigator.clipboard.writeText(generateTeamStatsHTML({
			teamStats,
			tableRows,
			key: teamStat?.key || Date.now(),
			language
		}));
		try {
			toast.success("Banner copied to clipboard successfully!");
		}
		catch (err) {
			toast.error("Something went wrong");
		}
	}
	useEffect(() => {
		$('#createTeamStatisticModal').on('hidden.bs.modal', function (e) {
			dispatch(teamStatsSetDetail(null))
		})
	}, [])
	return (
		<ModalWrapper
			title={"Build team statistic banner"}
			id={"createTeamStatisticModal"}
			titleId={"createTeamStatisticModalLabel"}
		>
			<form onSubmit={handleSaveBanner}>
				<div className="modal-body flex-column" >
					<div className="d-flex w-100 justify-content-between">
						<SelectPicker
							label={"Country"}
							onChange={handleSetData}
							value={state.country}
							options={countries}
							className="top-input-dark"
							name="country"
							style={{ width: "100%" }}
						/>
						<SelectPicker
							label={"League"}
							onChange={handleSetData}
							value={state.leagueId}
							options={leagues}
							className="top-input-dark"
							name="leagueId"
							style={{ width: "100%" }}
							disabled={!state.country}
						/>
						<SelectPicker
							label={"First Team"}
							onChange={handleSetData}
							value={state.firstTeamId}
							options={teams}
							className="top-input-dark"
							name="firstTeamId"
							style={{ width: "100%" }}
							disabled={!state.leagueId}
						/>
						<SelectPicker
							label={"Second Team"}
							onChange={handleSetData}
							value={state.secondTeamId}
							options={teams}
							className="top-input-dark"
							name="secondTeamId"
							style={{ width: "100%" }}
							disabled={!state.leagueId}
						/>
					</div>
					{(state.firstTeamId && state.secondTeamId) && (
						<div className="w-100 mt-4">
							<TeamStatisticBanner
								data={state}
							/>
						</div>
					)}
				</div>
				<div className="modal-footer">
					{(state.firstTeamId && state.secondTeamId) && (
						<Button
							label="Copy Banner"
							type="button"
							onClick={handleCopyBanner}
						/>
					)}
					<Button
						isLoading={btnLoading}
						label="Save Banner"
						type="submit"
					/>
				</div>
			</form>
		</ModalWrapper>
	)
}
export default CreateTeamStatisticModal;