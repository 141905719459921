import {
  NET_WORTH_LOADING,
  NET_WORTH_SET_DATA,
  NET_WORTH_SET_LANGUAGE,
  NET_WORTH_REMOVE,
  NET_WORTH_SET_DETAILS
} from "./constants";

const initialState = {
  netWorths: [],
  loading: false,
  language: "en",
  netWorth: {}
};

export function netWorthReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case NET_WORTH_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case NET_WORTH_SET_DATA:
      return {
        ...state,
        netWorths: action.payload
      };
    case NET_WORTH_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case NET_WORTH_REMOVE:
      return {
        ...state,
        netWorths: state.netWorths.filter(networth => networth.key !== action.payload)
      }
    case NET_WORTH_SET_DETAILS:
      return {
        ...state,
        netWorth: action.payload
      };
    default:
      return state;
  }
}
