const itITTranslation = {
  'Correct Score': 'Risultato esatto',
  'Full Time Result': 'Risultato finale',
  '1st Half - 3-Way': '1° tempo Squadra 1 Pareggio Squadra 2',
  'Double Chance': 'Doppia chance',
  'Both Teams to Score': 'Entrambe le squadre a segno',
  'Draw No Bet': 'Draw No Bet',
  Handicap: 'Handicap',
  '2nd Half 3-Way': '2° tempo Squadra 1 Pareggio Squadra 2',
  '1st Half - Both Teams to Score':
    'Entrambe le squadre a segno nel primo tempo',
  'First Goalscorer': 'Primo marcatore',
  'Anytime Goalscorer': 'Marcatore',
  'Highest Scoring Half': 'Tempo con più gol',
  'Last Goalscorer': 'Ultimo marcatore',
  'Asian Handicap': 'Handicap asiatico',
  'Score in Both Halves': 'Almeno un gol segnato in entrambi i tempi',
  'Win Both Halves': 'Vince entrambi i tempi',
  'Win Either Half': 'Vince almeno un tempo',
  'Odd or Even Total': 'Totale gol Pari/Dispari',
  'To Win To Nil': 'Vince a zero',
  'Clean Sheet': 'Non subisce gol',
  'Match Result and Both Teams To Score':
    'Esito finale con entrambe le squadre a segno',
  'Half-time Odd or Even Total': "Totale gol Pari/Dispari all'intervallo",
  'Total Goals Over/Under': 'Totale gol Over/Under',
  'Half-time Correct Score': "Risultato esatto all'intervallo",
  'Match Result and Total Goals Over/Under':
    'Esito finale e totale gol Over/Under',
  'Half-time Handicap': "Handicap all'intervallo",
  'Hat-trick': 'Tripletta sì',
  'Away Team Total Goals Over/Under': 'Totale gol Over/Under squadra ospite',
  'Half-time Totals Over/Under': "Totale gol Over/Under all'intervallo",
  'Home Team Total Goals Over/Under': 'Totale gol Over/Under squadra casa',
  'Half-time Draw No Bet': "Draw No Bet all'intervallo",
  '2nd Half Correct Score': 'Risultato esatto del secondo tempo',
  'Half-time/Full-time': 'Parziale/Finale',
  'Total Corners Over/Under': "Totale calci d'angolo Over/Under",
  'Booking Points Over/Under': 'Numero totale dei cartellini Over/Under',
  BetBuilder: 'BetBuilder',
  MatchCombo: 'Combo',
  'Home Team Total Corners Over/Under':
    "Totale calci d'angolo Over/Under squadra casa",
  'Away Team Total Corners Over/Under':
    "Totale calci d'angolo Over/Under squadra ospite",
  'Total Corners 1st Half Over/Under':
    "Totale calci d'angolo Over/Under all'intervallo",
  'Total Corners 2nd Half Over/Under':
    "Totale calci d'angolo Over/Under nel secondo tempo",
  'Home Team Booking Points Over/Under':
    'Squadra di casa numero dei cartellini Over/Under',
  'Away Team Booking Points Over/Under':
    'Squadra ospite numero dei cartellini Over/Under',
  'Most Corners': "Quale squadra collezionerà più calci d'angolo",
  '1st Half - Most Corners':
    "Quale squadra collezionerà più calci d'angolo all'intervallo",
  'Player To Be Carded': 'Giocatore sanzionato con un cartellino',
  'Player To Be Sent Off': 'Giocatore espulso',
  'Player to Have One or More Assists': 'Fornirà uno o più assist',
  'Player to Have Two or More Assists': 'Fornirà due o più assist',
  'Player to Have Three or More Assists': 'Fornirà tre o più assist',
  'Player to Score from Outside the Box': 'Realizzerà una rete da fuori area',
  'Player to Score a Header': 'Segnerà con un colpo di testa',
  '1st Half - Player to Score from Outside the Box':
    'Realizzerà una rete da fuori area nel primo tempo',
  '1st Half - Player to Score a Header':
    'Segnerà con un colpo di testa nel primo tempo',
  'Total shots at goal': 'Numero totale di tiri verso la porta avversaria',
  'Total shots on target': 'Numero totale di tiri nello specchio',
  'Total passes': 'Numero totale di passaggi',
  'Home Team Total Shots at Goal':
    'Numero totale di tiri verso la porta della squadra ospite',
  'Away Team Total Shots at Goal':
    'Numero totale di tiri verso la porta della squadra di casa',
  'Home Team Total Shots on Target':
    'Numero totale di tiri della squadra di casa nello specchio',
  'Away Team Total Shots on Target':
    'Numero totale di tiri della squadra ospite nello specchio',
  'Home Team Total Passes': 'Numero totale di passaggi della squadra di casa',
  'Away Team Total Passes': 'Numero totale di passaggi della squadra ospite',
  oddsTerms:
    '*Quote soggette a variazioni. Si applicano limiti di puntata. Si applicano termini e condizioni.',
  'Outright Winner': 'Vincitore',
  'To Finish In Top 4': 'Finisce tra le prime 4',
  'To Be Relegated': 'Retrocede',
  'Player Top Goalscorer': 'Capocannoniere',
  "Bet Now": "CONTINUA!",
  "Bet Expired": "EXPIRED",
  "Strength": "Valore della squadra",
  "Attacking Potential": "Aspettativa di gol",
  "Defensive Potential": "Aspettativa di reti subite",
  "Poisson Distribussion": "Percentuale di possesso palla",
  "Strength H2H": "Confronto tra il valore delle due squadre",
  "Goals H2H": "Confronto in termini di gol",
  "Wins the Game": "Percentuale di vittoria"
}

export default itITTranslation
