import React from "react";
import Typography from "@material-ui/core/Typography";

export default function InitialMessage() {

    return (
            <Typography
                className="text-white text-align-center p-5 w-100"
            >
                No Data Found.
                    <br />
                Try a new or different Event ID.
                  </Typography>
    );
}