const getEventName = (eventName, outcome, event, lang) => {
    if (eventName === 'X') {
        if (lang === 'es_ES') {
            return 'Empate'
        } else if (lang === 'it_IT') {
            return 'Pareggio'
        } else if (lang === 'da_DK') {
            return 'Uafgjort'
        } else if (lang === 'de_DE') {
            return 'Unentschieden'
        } else if (lang === 'ro_RO') {
            return 'Remiză'
        } else {
            return 'Draw'
        }
    } else if (eventName === 'Draw') {
        if (lang === 'es_ES') {
            return 'Empate'
        } else if (lang === 'it_IT') {
            return 'Pareggio'
        } else if (lang === 'da_DK') {
            return 'Uafgjort'
        } else if (lang === 'de_DE') {
            return 'Unentschieden'
        } else if (lang === 'ro_RO') {
            return 'Remiză'
        } else {
            return 'Draw'
        }
    } else if (eventName === 'Yes') {
        if (lang === 'es_ES') {
            return 'Si'
        } else if (lang === 'it_IT') {
            return 'Sì'
        } else if (lang === 'da_DK') {
            return 'Ja'
        } else if (lang === 'ro_RO') {
            return 'Da'
        } else if (lang === 'de_DE') {
            return 'Ja'
        } else {
            return 'Yes'
        }
    } else if (eventName === 'No') {
        if (lang === 'es_ES') {
            return 'No'
        } else if (lang === 'it_IT') {
            return 'No'
        } else if (lang === 'da_DK') {
            return 'Nej'
        } else if (lang === 'ro_RO') {
            return 'Nu'
        } else if (lang === 'de_DE') {
            return 'Nein'
        } else {
            return 'No'
        }
    } else if (eventName === 'France') {
        if (lang === 'es_ES') {
            return 'Francia'
        } else if (lang === 'it_IT') {
            return 'Francia'
        } else if (lang === 'da_DK') {
            return 'Frankrig'
        } else if (lang === 'ro_RO') {
            return 'Franța'
        } else if (lang === 'de_DE') {
            return 'Frankreich'
        } else {
            return 'France'
        }
    } else if (eventName === 'Poland') {
        if (lang === 'es_ES') {
            return 'Polonia'
        } else if (lang === 'it_IT') {
            return 'Polonia'
        } else if (lang === 'da_DK') {
            return 'Polen'
        } else if (lang === 'ro_RO') {
            return 'Polonia'
        } else if (lang === 'de_DE') {
            return 'Polen'
        } else {
            return 'Poland'
        }
    } else if (eventName === 'Australia') {
        if (lang === 'es_ES') {
            return 'Australia'
        } else if (lang === 'it_IT') {
            return 'Australia'
        } else if (lang === 'da_DK') {
            return 'Australien'
        } else if (lang === 'ro_RO') {
            return 'Australia'
        } else if (lang === 'de_DE') {
            return 'Australien'
        } else {
            return 'Australia'
        }
    } else if (eventName === 'Nethelands') {
        if (lang === 'es_ES') {
            return 'Holanda'
        } else if (lang === 'it_IT') {
            return 'Olanda'
        } else if (lang === 'da_DK') {
            return 'Holland'
        } else if (lang === 'ro_RO') {
            return 'Olanda'
        } else if (lang === 'de_DE') {
            return 'Niederlande'
        } else {
            return 'Netherlands'
        }
    } else if (eventName === 'Croatia') {
        if (lang === 'es_ES') {
            return 'Croacia'
        } else if (lang === 'it_IT') {
            return 'Croazia'
        } else if (lang === 'da_DK') {
            return 'Kroatien'
        } else if (lang === 'ro_RO') {
            return 'Croația'
        } else if (lang === 'de_DE') {
            return 'Kroatien'
        } else {
            return 'Croatia'
        }
    } else if (eventName === 'Morocco') {
        if (lang === 'es_ES') {
            return 'Marruecos'
        } else if (lang === 'it_IT') {
            return 'Marocco'
        } else if (lang === 'da_DK') {
            return 'Marokko'
        } else if (lang === 'ro_RO') {
            return 'Maroc'
        } else if (lang === 'de_DE') {
            return 'Marokko'
        } else {
            return 'Morocco'
        }
    } else if (eventName === 'Sout Korea') {
        if (lang === 'es_ES') {
            return 'Corea del Sur'
        } else if (lang === 'it_IT') {
            return 'Corea del Sud'
        } else if (lang === 'da_DK') {
            return 'Sydkorea'
        } else if (lang === 'ro_RO') {
            return 'Coreea de Sud'
        } else if (lang === 'de_DE') {
            return 'Südkorea'
        } else {
            return 'South Korea'
        }
    } else if (eventName === 'Brasil') {
        if (lang === 'es_ES') {
            return 'Brasil'
        } else if (lang === 'it_IT') {
            return 'Brasile'
        } else if (lang === 'da_DK') {
            return 'Brasilien'
        } else if (lang === 'ro_RO') {
            return 'Brazilia'
        } else if (lang === 'de_DE') {
            return 'Brasilien'
        } else {
            return 'Brazil'
        }
    } else if (eventName === 'Spain') {
        if (lang === 'es_ES') {
            return 'España'
        } else if (lang === 'it_IT') {
            return 'Spagna'
        } else if (lang === 'da_DK') {
            return 'Spanien'
        } else if (lang === 'ro_RO') {
            return 'Spania'
        } else if (lang === 'de_DE') {
            return 'Spanien'
        } else {
            return 'Spain'
        }
    } else if (eventName === 'Portugal') {
        if (lang === 'es_ES') {
            return 'Portugal'
        } else if (lang === 'it_IT') {
            return 'Portogallo'
        } else if (lang === 'da_DK') {
            return 'Portugal'
        } else if (lang === 'ro_RO') {
            return 'Portugalia'
        } else if (lang === 'de_DE') {
            return 'Portugal'
        } else {
            return 'Portugal'
        }
    } else if (eventName === 'Argentina') {
        if (lang === 'es_ES') {
            return 'Argentina'
        } else if (lang === 'it_IT') {
            return 'Argentina'
        } else if (lang === 'da_DK') {
            return 'Argentina'
        } else if (lang === 'ro_RO') {
            return 'Argentina'
        } else if (lang === 'de_DE') {
            return 'Argentinien'
        } else {
            return 'Argentina'
        }
    } else if (eventName === 'Switzerland') {
        if (lang === 'es_ES') {
            return 'Suiza'
        } else if (lang === 'it_IT') {
            return 'Svizzera'
        } else if (lang === 'da_DK') {
            return 'Schweiz'
        } else if (lang === 'ro_RO') {
            return 'Elveția'
        } else if (lang === 'de_DE') {
            return 'Schweiz'
        } else {
            return 'Switzerland'
        }
    } else if (eventName === 'Under') {
        if (lang === 'es_ES') {
            if (outcome?.line) {
                return `Menos de ${parseFloat(outcome.line) / 1000}`
            } else {
                return 'Menos de'
            }
        } else {
            if (outcome?.line) {
                return `${eventName} ${parseFloat(outcome.line) / 1000}`
            } else {
                return eventName
            }
        }
    } else if (eventName === 'Over') {
        if (lang === 'es_ES') {
            if (outcome?.line) {
                return `Más de ${parseFloat(outcome.line) / 1000}`
            } else {
                return 'Más de'
            }
        } else {
            if (outcome?.line) {
                return `${eventName} ${parseFloat(outcome.line) / 1000}`
            } else {
                return eventName
            }
        }
    } else if (eventName === '1' && event.sport === 'TENNIS') {
        if (event?.homeName) return event.homeName
        else return eventName
    } else if (eventName === '2' && event.sport === 'TENNIS') {
        if (event?.awayName) return event.awayName
        else return eventName
    } else if (eventName === '1' && event.sport === 'MARTIAL_ARTS') {
        if (event?.homeName) return event.homeName
        else return eventName
    } else if (eventName === '2' && event.sport === 'MARTIAL_ARTS') {
        if (event?.awayName) return event.awayName
        else return eventName
    } else {
        if (outcome?.line)
            return `${eventName} ${parseFloat(outcome.line) / 1000}`
        else return eventName
    }
}
export default getEventName;