import { useState, useEffect } from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
import { fetchPredictionBanners } from "../../store/predictionBanners/actions";

const useFetchPredictionBanners = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { predictionBanners } = useSelector((state) => state.predictionBanners);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch(fetchPredictionBanners());
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return {
    loading,
    predictionBanners,
  };
};
export default useFetchPredictionBanners;
