import React from "react";
import './AdminView.scss';
import { makeStyles } from "@material-ui/core/styles";
import {NavLink} from 'react-router-dom';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// components

//redux
import { connect } from "react-redux";
import { handleLogout } from "../../store/auth/actions";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    letterSpacing: 1.75
  },
  credits: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 12
  },
  logo: {
    maxWidth: "9.5% !important",
    marginRight: 8
  }
}));
const AdminView = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <main className="AdminView">
      <div className="AdminView-header" >
      <AppBar position="static" className='bg-dark'>
        <Toolbar>
          <img src="odin.png" alt="" className={classes.logo} />
          <h2 id="runic-title" className={classes.title}>
            Oddin
          </h2>
        </Toolbar>
      </AppBar>
        <NavLink to='/bet-generator' className='' >
          Bet Generator
        </NavLink>
        <NavLink to='/net-worth-generator' className='' >
          Net Worth Generator
        </NavLink>
        <NavLink to='/team-statistics-generator' className='' >
          Team Statistics Generator
        </NavLink>
        <NavLink to='/predictions-generator' className='' >
          Prediction Banner Generator
        </NavLink>
        {/* eslint-disable-next-line */}
        <a href="#" className='' onClick={()=>props.handleLogout()}>          
          Logout
        </a>
        <p className={classes.credits}>Oddin is carefully crafted by Guy Dahan for over 4 years.</p>
        <p className={classes.credits}>For support, ask your direct manager, or click on support.</p>
        <a href='https://www.guy-dev.com' className='' >
        🛟 Support
        </a>
      </div>
      <div className="AdminView-view">{children}</div>
    </main>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  handleLogout
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminView);