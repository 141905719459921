import {
  NET_WORTH_LOADING,
  NET_WORTH_SET_DATA,
  NET_WORTH_SET_LANGUAGE,
  NET_WORTH_REMOVE,
  NET_WORTH_SET_DETAILS,
} from "./constants";
import { _fetchNetWorths, _createNetWorth, _deleteNetWorth, _updateNetWorth } from "./services";

export function netWorthSetLoading(loading) {
  return {
    type: NET_WORTH_LOADING,
    payload: loading,
  };
}

export function netWorthSetData(data) {
  return {
    type: NET_WORTH_SET_DATA,
    payload: data,
  };
}

export function netWorthSetLanguage(data) {
  return {
    type: NET_WORTH_SET_LANGUAGE,
    payload: data,
  };
}
export function netWorthRemove(data) {
  return {
    type: NET_WORTH_REMOVE,
    payload: data,
  };
}
export function netWorthSetDetails(data) {
  return {
    type: NET_WORTH_SET_DETAILS,
    payload: data,
  };
}
export const fetchNetWorths = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(netWorthSetLoading(true));
    _fetchNetWorths().then(async (res) => {
      dispatch(netWorthSetData(res))
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(netWorthSetLoading(false));
    })
  })
};
export const createNetWorth = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(netWorthSetLoading(true));
    _createNetWorth(formData).then(async (res) => {
      await dispatch(fetchNetWorths());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(netWorthSetLoading(false));
    })
  })
};
export const deleteNetWorth = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(netWorthSetLoading(true));
    _deleteNetWorth(key).then(async (res) => {
      await dispatch(netWorthRemove(key));
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(netWorthSetLoading(false));
    })
  })
};
export const updateNetWorth = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(netWorthSetLoading(true));
    _updateNetWorth(formData).then(async (res) => {
      await dispatch(fetchNetWorths());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(netWorthSetLoading(false));
    })
  })
};