// import dayjs from "dayjs";
//constants
// import translations from "../../constants/teamStatsTranslations";
//services
import { useEffect, useState } from "react";
import { fetchFootballAPIInfo } from "../../store/teamStats/services";
import deDETranslation from "../../locales/de_DE";
//redux
import { useSelector } from "react-redux";

const useGeneratePredictionBanner = (data) => {
  const { language } = useSelector((state) => state.predictionBanners);
  const [fixtures, setFixtures] = useState([]);
  const [predictionComparisons, setPredictionComparisons] = useState(null);
  const handleFetchFixtures = (leagueId, season) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await fetchFootballAPIInfo("fixtures", {
          league: leagueId,
          season,
          // h2h: `${firstTeamId}-${secondTeamId}`,
          status: "NS",
        });
        resolve(resp?.response);
      } catch (err) {
        reject(err);
      }
    });
  };
  const handleFetchPredictions = (fixtureId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await fetchFootballAPIInfo("predictions", {
          fixture: fixtureId,
        });
        resolve(resp?.response);
      } catch (err) {
        reject(err);
      }
    });
  };
  useEffect(() => {
    (async () => {
      if (
        data?.leagueId &&
        // data?.firstTeamId &&
        data?.season
        // data?.secondTeamId
      ) {
        handleFetchFixtures(data.leagueId, data.season)
          .then((resp) => {
            if (Array.isArray(resp)) {
              setFixtures(
                resp.map(({ fixture, teams }) => {
                  let homeName = teams?.home?.name;
                  let awayName = teams?.away?.name;
                  if (language === "de") {
                    if (deDETranslation[homeName]) {
                      homeName = deDETranslation[homeName];
                    }
                    if (deDETranslation[awayName]) {
                      awayName = deDETranslation[awayName];
                    }
                  }
                  return {
                    label: `${homeName} VS ${awayName}`,
                    value: fixture.id,
                  };
                })
                // resp.map(({ fixture }) => ({
                //   label: `${fixture?.status?.long} (${dayjs(
                //     fixture?.date
                //   ).format("YYYY-MM-DD")})`,
                //   value: fixture.id,
                // }))
              );
            }
          })
          .catch((err) => {});
      }
      if (data?.fixtureId) {
        handleFetchPredictions(data?.fixtureId).then((resp) => {
          if (Array.isArray(resp)) {
            setPredictionComparisons(resp[0]?.comparison);
          }
        });
      }
    })();
  }, [data, language]);
  return {
    fixtures,
    predictionComparisons,
  };
};
export default useGeneratePredictionBanner;
