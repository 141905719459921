const Button = ({
	isLoading = false,
	label = "Submit",
	type = "button",
	onClick = () => { },
	...props
}) => {
	return (
		<button
			type={type}
			className="btn btn-primary"
			disabled={isLoading}
			onClick={onClick}
			{...props}
		>
			{isLoading
				? <div className="spinner-border text-light"></div>
				: label}
		</button>
	)
}
export default Button