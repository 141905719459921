import { useState, useEffect } from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamStatsBanners } from "../../store/teamStats/actions";

const useFetchTeamStats = () => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const {teamStats: teamBanners} = useSelector(state => state.teamStats)
	useEffect(() => {
		(async () => {
			try{
				setLoading(true)
				await dispatch(fetchTeamStatsBanners())
			}catch(err){

			}finally{
				setLoading(false)
			}
		})()
	}, [])
	return {
		loading,
		teamBanners
	}
}
export default useFetchTeamStats