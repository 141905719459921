import Swal from "sweetalert2";
export const showDeleteAlert = ({title, text, icon}) => {
	return new Promise((resolve, reject) => {
		Swal.fire({
			title,
			text,
			icon,
			showCancelButton: true,
			confirmButtonColor: '#fd6301',
			cancelButtonColor: '#343a40',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				resolve()
			}else{
				reject()
			}
		})
	})
}