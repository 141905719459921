import React, { useEffect, useState } from "react";
import "./showNetWorthModal.css";
import { GenerateNetWorthHTML } from "../../utils/GenerateNetWorthHTML";
import axios from "axios";
import translations from "../../constants/netWorthTranslations";
//redux
import { connect } from "react-redux";
import { toast } from "react-toastify";
const ShowNetWorthModal = (props) => {
	const startTime = new Date() / 1000;
	const [currentWorth, setCurrentWorth] = useState(0);
	const [loading, setLoading] = useState(false);
	const [convertedCurrencies, setConvertedCurrencies] = useState({
		yearlyIncomeGBP: 0,
		monthlyIncomeGBP: 0,
		weeklyIncomeGBP: 0,
		dailyIncomeGBP: 0,
		hourlyIncomeGBP: 0,
		perMinuteIncomeGBP: 0,
	});
	const [currencySymbol, setCurrencySymbol] = useState("£");

	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	const calculateWorth = (unit) => {
		if (!isNaN(props.netWorth?.yearlyIncome)) {			// eslint-disable-next-line 
			switch (unit) {
				case "year":
					return props.netWorth.yearlyIncome;
				case "month":
					return parseInt(parseFloat(props.netWorth.yearlyIncome) / 12);
				case "week":
					return parseInt(parseFloat(props.netWorth.yearlyIncome) / 52);
				case "day":
					return parseInt(parseFloat(props.netWorth.yearlyIncome) / 365);
				case "hour":
					return parseInt(parseFloat(props.netWorth.yearlyIncome) / 8760);
				case "minute":
					return parseInt(parseFloat(props.netWorth.yearlyIncome) / 525600);
				case "second":
					return parseFloat(props.netWorth.yearlyIncome) / 31540000;
			}
		} else {
			return "N/A";
		}
	}
	const calculateCurrentWorth = () => {
		var timeNow = new Date() / 1000;
		var msSinceViewing = Math.ceil((timeNow - startTime));
		var wageSinceViewing = msSinceViewing * calculateWorth("second");
		setCurrentWorth(wageSinceViewing.toFixed(2));
	}
	const convertCurrency = async (amount) => {
		return new Promise(async (resolve, reject) => {
			try {
				let toCurrency = "GBP";
				if(props.language === "sv"){
					toCurrency = "SEK";
					setCurrencySymbol("kr ")
				} else if (props.language === "da"){
					toCurrency = "DKK";
					setCurrencySymbol("kr. ")
				}
				const resp = await axios.get(`https://data.fixer.io/api/convert?access_key=8d74d85bbbdfa05a5f3edbffa466d79d&from=EUR&to=${toCurrency}&amount=${amount}`);
				resolve(resp.data);
			} catch (err) {
				reject(null);
			}
		});
	}
	const handleConvertCurrencies = async () => {
		try {
			setLoading(true);
			let promises = [];
			promises.push(convertCurrency(calculateWorth("year")));
			promises.push(convertCurrency(calculateWorth("month")));
			promises.push(convertCurrency(calculateWorth("week")));
			promises.push(convertCurrency(calculateWorth("day")));
			promises.push(convertCurrency(calculateWorth("hour")));
			promises.push(convertCurrency(calculateWorth("minute")));

			const resp = await Promise.all(promises);
			let yearlyIncomeGBP = 0,
				monthlyIncomeGBP = 0,
				weeklyIncomeGBP = 0,
				dailyIncomeGBP = 0,
				hourlyIncomeGBP = 0,
				perMinuteIncomeGBP = 0;

			if (!isNaN(resp[0]?.result)) {
				yearlyIncomeGBP = parseInt(resp[0].result);
			}
			if (!isNaN(resp[1]?.result)) {
				monthlyIncomeGBP = parseInt(resp[1].result);
			}
			if (!isNaN(resp[2]?.result)) {
				weeklyIncomeGBP = parseInt(resp[2].result);
			}
			if (!isNaN(resp[3]?.result)) {
				dailyIncomeGBP = parseInt(resp[3].result);
			}
			if (!isNaN(resp[4]?.result)) {
				hourlyIncomeGBP = parseInt(resp[4].result);
			}
			if (!isNaN(resp[5]?.result)) {
				perMinuteIncomeGBP = parseInt(resp[5].result);
			}
			setConvertedCurrencies({
				...convertedCurrencies,
				yearlyIncomeGBP,
				monthlyIncomeGBP,
				weeklyIncomeGBP,
				dailyIncomeGBP,
				hourlyIncomeGBP,
				perMinuteIncomeGBP
			})
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}
	const handleCopyToClickboard = () => {
		navigator.clipboard.writeText(GenerateNetWorthHTML(props.netWorth, convertedCurrencies, props.language, currencySymbol));
		try {
			toast.success("Banner copied to clipboard successfully!");
		} catch (err) {
			toast.error("Something went wrong");
		}
	};
	useEffect(() => {
		let interval = null;
		if (!isNaN(props.netWorth?.yearlyIncome)) {
			calculateCurrentWorth();
			handleConvertCurrencies();

			interval = setInterval(() => {
				calculateCurrentWorth()
			}, 1000)
		}
		return () => clearInterval(interval)			// eslint-disable-next-line 
	}, [props.netWorth, props.language])
	return (
		<div className="modal fade" id="showNetWorthModal" tabindex="-1" role="dialog" aria-labelledby="showNetWorthModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-body" style={{ flexDirection: "column" }}>
						{loading ? <p>Generating net worth banner...</p> : (
							<>
								<div className="column2" style={{ "backgroundColor": "#FFFFFF" }}>
									<br />
									<table className="colored">
										<tbody>
											<tr>
												<td>
													<b>{translations.perYear[props.language]}</b>
												</td>
												<td>
													€{numberWithCommas(calculateWorth("year"))}
												</td>
												<td>
													{currencySymbol}{numberWithCommas(convertedCurrencies.yearlyIncomeGBP)}
												</td>
											</tr>
											<tr>
												<td>
													<b>{translations.perMonth[props.language]}</b>
												</td>
												<td>
													€{numberWithCommas(calculateWorth("month"))}
												</td>
												<td>
												{currencySymbol}{numberWithCommas(convertedCurrencies.monthlyIncomeGBP)}
												</td>
											</tr>
											<tr>
												<td>
													<b>{translations.perWeek[props.language]}</b>
												</td>
												<td>
													€{numberWithCommas(calculateWorth("week"))}
												</td>
												<td>
												{currencySymbol}{numberWithCommas(convertedCurrencies.weeklyIncomeGBP)}
												</td>
											</tr>
											<tr>
												<td>
													<b>{translations.perDay[props.language]}</b>
												</td>
												<td>
													€{numberWithCommas(calculateWorth("day"))}
												</td>
												<td>
												{currencySymbol}{numberWithCommas(convertedCurrencies.dailyIncomeGBP)}
												</td>
											</tr>
											<tr>
												<td>
													<b>{translations.perHour[props.language]}</b>
												</td>
												<td>
													€{numberWithCommas(calculateWorth("hour"))}
												</td>
												<td>
												{currencySymbol}{numberWithCommas(convertedCurrencies.hourlyIncomeGBP)}
												</td>
											</tr>
											<tr>
												<td>
													<b>{translations.perMinute[props.language]}</b>
												</td>
												<td>
													€{numberWithCommas(calculateWorth("minute"))}
												</td>
												<td>
												{currencySymbol}{numberWithCommas(convertedCurrencies.perMinuteIncomeGBP)}
												</td>
											</tr>
										</tbody>
									</table>
									<br />
								</div>
								<div className="moneyclockclass1"><b>{translations.textPart1[props.language]} {props.netWorth.name} {translations.textPart2[props.language]}</b><div id="moneyclock" className="moneyclockclass2">€{currentWorth}</div></div>
							</>
						)}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-dark" data-dismiss="modal" aria-label="Close">Close</button>
						{!loading && (
							<>
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => handleCopyToClickboard()}
								>
									Copy Banner
								</button>
								<button type="button" className="btn btn-primary" data-toggle="modal"
									data-dismiss="modal"
									data-target="#editNetWorthModal" >
									Edit Banner
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({
	netWorth: state.netWorth.netWorth,
	language: state.netWorth.language,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ShowNetWorthModal);