import React from "react";
import { Route, Redirect } from "react-router-dom";

//redux
import { connect } from "react-redux";

const RouteWithLayout = (props) => {
	const {
		layout: Layout,
		component: Component,
		restricted,
		access,
		scope,
		...rest
	} = props;

	if (access === "public")
		return (
			<Route
				{...rest}
				render={(matchProps) =>
					props.token && restricted ? (
						<Redirect to="/bet-generator" />
					) : (
						<Layout>
							<Component {...matchProps} />
						</Layout>
					)
				}
			/>
		);
	else if (access === "private")
		return (
			<Route
				{...rest}
				render={(matchProps) =>
					props.token ? (
						<Layout>
							<Component {...matchProps} />
						</Layout>
					) : (
						<Redirect to="/" />
					)
				}
			/>
		);
};
const mapStateToProps = (state) => ({
	loading: state.auth.loading,
	token: state.auth.token,
});
export default connect(mapStateToProps, {
})(RouteWithLayout);