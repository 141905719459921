import {
  PREDICTION_BANNER_LOADING,
  PREDICTION_BANNER_SET_DATA,
  PREDICTION_BANNER_SET_LANGUAGE,
  PREDICTION_BANNER_SET_DETAIL
} from "./constants";
import { 
  _fetchPredictionBanners, 
  _createPredictionBanners, 
  _deletePredictionBanners, 
  _updatePredictionBanners 
} from "./services";

export function predictionSetLoading(loading) {
  return {
    type: PREDICTION_BANNER_LOADING,
    payload: loading,
  };
}

export function predictionSetData(data) {
  return {
    type: PREDICTION_BANNER_SET_DATA,
    payload: data,
  };
}

export function predictionSetLanguage(data) {
  return {
    type: PREDICTION_BANNER_SET_LANGUAGE,
    payload: data,
  };
}
export function predictionSetDetail(data) {
  return {
    type: PREDICTION_BANNER_SET_DETAIL,
    payload: data,
  };
}
export const fetchPredictionBanners = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(predictionSetLoading(true));
    _fetchPredictionBanners().then(async (res) => {
      dispatch(predictionSetData(res))
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(predictionSetLoading(false));
    })
  })
};
export const createPredictionBanner = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(predictionSetLoading(true));
    _createPredictionBanners(formData).then(async (res) => {
      await dispatch(fetchPredictionBanners());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(predictionSetLoading(false));
    })
  })
};
export const deletePredictionBanner = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(predictionSetLoading(true));
    _deletePredictionBanners(key).then(async (res) => {
      await dispatch(fetchPredictionBanners());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(predictionSetLoading(false));
    })
  })
};
export const updatePredictionBanner = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(predictionSetLoading(true));
    _updatePredictionBanners(formData).then(async (res) => {
      await dispatch(fetchPredictionBanners());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(predictionSetLoading(false));
    })
  })
};