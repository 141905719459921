import React, { useState } from "react";
import { GenerateBetHTML } from "../utils/GenerateBetHTML";
import { GenerateSpectateBetHTML } from "../utils/GenerateSpectateBetHTML";
import BetBanner from "../components/BetBanner/BetBanner.jsx";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import { bucketSetItem, bucketDeleteItem } from "../store/bucket/actions";

const SvgModal = (props) => {
  const [bannerTitle, setBannerTitle] = useState('')
  const handleCopyToClickboard = async () => {
    console.log(props)
    navigator.clipboard.writeText(props.apiType === "kambi" ? GenerateBetHTML(props.bet, props.language, bannerTitle) : GenerateSpectateBetHTML(
      props.bet, 
      props.language, 
      props.betType, 
      bannerTitle,
      props.sport,
      props.category,
      props.tournament
    
    ));
    try {
      toast.success("Banner copied to clipboard successfully!");
    } catch (err) {
      toast.error("Something went wrong");
    }
  };
  const handleAddToBucket = () => {
    props.bucketSetItem({
      ...props.bet,
      sport: props.sport,
      category: props.category,
      tournament: props.tournament
    });
  };
  const handleRemoveFromBucket = () => {
    props.bucketDeleteItem(props.bet);
  };
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content specific-class">
          <div className="modal-body d-flex flex-column">
            {/* {GenerateSVG(props.bet, props.language)} */}
            <BetBanner
              bet={props.bet}
              lang={props.language}
              betType={props.betType}
              bannerTitle={bannerTitle}
            />
            <div className="form-group mt-5 w-100 px-3">
              <input
                type="text"
                className="form-control"
                id="net_worth_name"
                placeholder="Enter Banner Title"
                required
                value={bannerTitle}
                onChange={(e) => setBannerTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" data-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleCopyToClickboard();
              }}
            >
              COPY BANNER
            </button>
            <>
              {props.items?.some((item) => item.id === props.bet?.id) ? (
                <button
                  type="button"
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                  onClick={() => {
                    handleRemoveFromBucket();
                  }}
                >
                  <FaTrash style={{ marginRight: 10 }} /> REMOVE FROM BUCKET
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                  onClick={() => {
                    handleAddToBucket();
                  }}
                >
                  <FaPlus style={{ marginRight: 10 }} /> ADD TO BUCKET
                </button>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  items: state.bucket.items,
  betType: state.spectate.betType,
  apiType: state.bucket.apiType,
  sport: state.spectate.sport,
  category: state.spectate.category,
  tournament: state.spectate.tournament,
});
const mapDispatchToProps = {
  bucketSetItem,
  bucketDeleteItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(SvgModal);