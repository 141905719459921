import {
  BUCKET_SET_LOADING,
  BUCKET_SET_ITEM,
  BUCKET_DELETE_ITEM,
  BUCKET_UPDATE_SORTING_ORDER,
  BUCKET_SET_API_TYPE,
  BUCKET_RESET
} from "./constants";

const initialState = {
  loading: false,
  items: [],
  apiType: "kambi"
};

export function bucketReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case BUCKET_SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case BUCKET_SET_ITEM:
      let prevItems = state.items;
      let isExist = prevItems.some(item => item.id === action.payload.id);
      if (!isExist) {
        prevItems.push(action.payload);
      }
      return {
        ...state,
        items: [...prevItems]
      };
    case BUCKET_DELETE_ITEM:
      return {
        ...state,
        items: [...state.items.filter(item => item.id !== action.payload.id)]
      };
    case BUCKET_UPDATE_SORTING_ORDER:
      return {
        ...state,
        items: [...action.payload]
      }
    case BUCKET_SET_API_TYPE:
      return {
        ...state,
        apiType: action.payload
      }
    case BUCKET_RESET:
      return {
        ...state,
        items: []
      }
    default:
      return state;
  }
}
