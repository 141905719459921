const translations = {
    seeLast: {
        en: "See last statistics on ",
        de: "Letzte Statistiken sehen auf ",
        es: "Ver últimas estadísticas del ",
        it: "Controlla le ultime statistiche relative ",
        ro: "Vezi ultimele statistici ",
    },
    home: {
        en: "HOME",
        de: "HEIM",
        es: "LOCAL",
        it: "CASA",
        ro: "ACASĂ",
    },
    away: {
        en: "AWAY",
        de: "AUSWÄRTS",
        es: "VISITANTE",
        it: "TRASFERTA",
        ro: "ÎN DEPLASARE",
    },
    all: {
        en: "ALL",
        de: "ALLE",
        es: "TODOS",
        it: "TUTTE",
        ro: "TOATE MECIURILE",
    },
    gamesPlayed: {
        en: "Games Played",
        de: "Spiele",
        es: "PARTIDOS JUGADOS",
        it: "Partite giocate",
        ro: "Meciuri jucate",
    },
    wins: {
        en: "Wins",
        de: "Siege",
        es: "GANADOS",
        it: "Vittorie",
        ro: "Victorii",
    },
    draws: {
        en: "Draws",
        de: "Unentschieden",
        es: "EMPATADOS",
        it: "Pareggi",
        ro: "Egaluri",
    },
    loss: {
        en: "Loss",
        de: "Niederlagen",
        es: "PERDIDOS",
        it: "Sconfitte",
        ro: "Înfrângeri",
    },
    goalsFor: {
        en: "Goals For",
        de: "Tore für",
        es: "Goles a Favor",
        it: "Reti realizzate",
        ro: "Goluri marcate",
    },
    goalsAgainst: {
        en: "Goals Against",
        de: "Tore gegen",
        es: "Goles en Contra",
        it: "Reti subite",
        ro: "Goluri primite",
    },
    disclaimer: {
        en: "*Stats based on last and current season; Subject to current changes.",
        de: "*Statistiken basieren auf der letzten und laufenden Saison; Änderungen Vorbehalten.",
        es: "*Estadísticas basadas en la pasada temporada y en la actual; Datos sujetos a cambios.",
        it: "*Le statistiche sono elaborate sull'ultimo campionato e quello in corso e sono aggiornate constantemente.",
        ro: "*Statistici bazate pr rezultatele din ultimul sezon și cel actual; Datele pot suferi modificări în orice moment.",
    },
}
export default translations;