import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var config = {
    apiKey: "AIzaSyABUaqVjCTcDBS8GW0IIq6gP5kOACb4Q-A",
    authDomain: "odd-picker.firebaseapp.com",
    databaseURL: "https://odd-picker.firebaseio.com",
    projectId: "odd-picker",
    storageBucket: "odd-picker.appspot.com",
    messagingSenderId: "380971256382",
    appId: "1:380971256382:web:cd432936187b0e7ed2df6f",
    measurementId: "G-G16KFD7YE1",
};
firebase.initializeApp(config);
export default firebase;