import {
  SPECTATE_LOADING,
  SPECTATE_SET_DATA,
  SPECTATE_SET_LANGUAGE,
  SPECTATE_SET_BET_TYPE,
  SPECTATE_SET_SPORT,
  SPECTATE_SET_CATEGORY,
  SPECTATE_SET_TOURNAMENT
} from "./constants";
import { _fetchEvents } from "./services";

export function spectateSetLoading(loading) {
  return {
    type: SPECTATE_LOADING,
    payload: loading,
  };
}

export function spectateSetData(data) {
  return {
    type: SPECTATE_SET_DATA,
    payload: data,
  };
}

export function spectateSetLanguage(data) {
  return {
    type: SPECTATE_SET_LANGUAGE,
    payload: data,
  };
}

export function spectateSetBetType(data) {
  return {
    type: SPECTATE_SET_BET_TYPE,
    payload: data,
  };
}
export const fetchEvents = (link) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(spectateSetLoading(true));
    _fetchEvents(link).then(async (res) => {
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(spectateSetLoading(false));
    })
  })
};
export function spectateSetSport(data) {
  return {
    type: SPECTATE_SET_SPORT,
    payload: data,
  };
}
export function spectateSetCategory(data) {
  return {
    type: SPECTATE_SET_CATEGORY,
    payload: data,
  };
}
export function spectateSetTournament(data) {
  return {
    type: SPECTATE_SET_TOURNAMENT,
    payload: data,
  };
}