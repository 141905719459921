import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//constants
import translations from "../../constants/teamStatsTranslations";
//hooks
import useGenerateTeamStatsBanner from "../../hooks/teamStats/useGenerateTeamStatsBanner";
//redux
import { useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fd6301",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
  root: {
    backgroundColor: (props) => {
      if (props.cellType === "header") {
        return "#fd6301";
      } else {
        let color = "#fff";
        if (
          props.column === "all" ||
          ["Goals", "Goals Average"].includes(props.children) ||
          props.children === ""
        ) {
          color = theme.palette.action.hover;
        }
        return color;
      }
    },
    fontWeight: (props) =>
      ["Goals", "Goals Average"].includes(props.children) ? "bold" : "inherit",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-child(5)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:nth-child(8)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function TeamStatisticBanner({ data }) {
  const classes = useStyles();
  const { language } = useSelector((state) => state.teamStats);
  const { teamStats, tableRows } = useGenerateTeamStatsBanner(data);
  return (
    <div>
      {Array.isArray(teamStats) && teamStats?.length > 0 && (
        <div className="row mb-3">
          <div className="col-12">
            <h3
              className="d-flex justify-content-center"
              style={{ fontSize: 22 }}
            >
              {translations.seeLast[language]} {teamStats[0]?.team?.name} vs{" "}
              {teamStats[1]?.team?.name}
            </h3>
          </div>
        </div>
      )}
      <div className="row mb-2">
        <div className="col-3"></div>
        {teamStats.map((teamStat, i) => (
          <div
            className="col-4 text-center d-flex justify-content-center align-items-center"
            key={i}
          >
            <b>{teamStat?.team?.name}</b>
            {/* <img 
							src={teamStat?.team?.logo} 
							alt={teamStat?.team?.name}
							style={{ width: 30, height: 30 }} 
							className='ml-2'
						/> */}
          </div>
        ))}
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell cellType="header"></StyledTableCell>
              <StyledTableCell cellType="header" align="center">
                {translations.home[language]}
              </StyledTableCell>
              <StyledTableCell cellType="header" align="center">
                {translations.away[language]}
              </StyledTableCell>
              <StyledTableCell cellType="header" align="center">
                {translations.all[language]}
              </StyledTableCell>
              <StyledTableCell cellType="header" align="center">
                {translations.home[language]}
              </StyledTableCell>
              <StyledTableCell cellType="header" align="center">
                {translations.away[language]}
              </StyledTableCell>
              <StyledTableCell cellType="header" align="center">
                {translations.all[language]}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell cellType="row" column="category">
                  {row.category}
                </StyledTableCell>
                <StyledTableCell cellType="row" align="center" column="home">
                  {row.first_home}
                </StyledTableCell>
                <StyledTableCell cellType="row" align="center" column="away">
                  {row.first_away}
                </StyledTableCell>
                <StyledTableCell cellType="row" align="center" column="all">
                  {row.first_all}
                </StyledTableCell>
                <StyledTableCell cellType="row" align="center" column="home">
                  {row.second_home}
                </StyledTableCell>
                <StyledTableCell cellType="row" align="center" column="away">
                  {row.second_away}
                </StyledTableCell>
                <StyledTableCell cellType="row" align="center" column="all">
                  {row.second_all}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <small className="mt-3 d-inline-block">
        {translations.disclaimer[language]}
      </small>
    </div>
  );
}
