import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Loader from "../Loader";
import { FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";

//hooks
import useFetchTeamStats from "../../hooks/teamStats/useFetchTeamStats";
//utils
import { showDeleteAlert } from "../../utils";
//redux
import { useDispatch } from "react-redux";
import {
  deleteTeamStatsBanner,
  teamStatsSetDetail,
} from "../../store/teamStats/actions";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "90%",
  },
  headText: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
    borderColor: "#fff",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabRow: {
    width: "90%",
    backgroundColor: "#222",
    borderColor: "#0000",
  },
  tabRowInner: {
    borderColor: "#0000",
  },
}));
const TeamStatisticTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleDelete = async (id) => {
    try {
      showDeleteAlert({
        title: "Are you sure want to delete this record?",
        text: "You won't be able to revert this!",
        icon: "warning",
      }).then(async () => {
        await dispatch(deleteTeamStatsBanner(id));
        toast.success("Banner deleted successfully!");
      });
    } catch (err) {
      toast.error("Something went wrong");
    }
  };
  const handleClick = (item) => {
    dispatch(teamStatsSetDetail(item));
  };
  const { teamBanners, loading } = useFetchTeamStats();
  return (
    <TableContainer component={Paper} className="bg-dark">
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {loading ? (
            <TableRow
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
                width: "70vw",
                margin: "auto",
              }}
            >
              <TableCell
                colSpan={3}
                scope="row"
                className={classes.tabRowInner}
              >
                <Loader />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {teamBanners.map((item, i) => (
                <TableRow className={classes.tabRow} key={i}>
                  <TableCell
                    colSpan={3}
                    scope="row"
                    className={classes.tabRowInner}
                  >
                    <div className="accordion-item d-flex">
                      <button
                        className="accordion-button btn btn-dark btn-accordian px-4 py-2 collapsed w-100"
                        type="button"
                        data-toggle="modal"
                        data-target="#createTeamStatisticModal"
                        onClick={() => handleClick(item)}
                      >
                        <div className="row">
                          <div className="col-5">
                            <div className="row btn-colasable">
                              <h5 className="col-12 text-left">
                                {item.leagueName}
                              </h5>
                            </div>
                          </div>
                          <div className="col-7">
                            <div className="row btn-colasable">
                              <p className="col-10 text-align-left">
                                {/* {getMappingCount(cat.mappings)} */}
                                {item.firstTeamName} VS {item.secondTeamName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                      <button
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => handleDelete(item.key)}
                      >
                        <FiTrash color={"#fff"} />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TeamStatisticTable;
