import React from "react";
import Header from "../../components/teamStatistic/Header";
import TeamStatisticTable from "../../components/teamStatistic/TeamStatisticTable";
import CreateTeamStatisticModal from "../../components/teamStatistic/CreateTeamStatisticModal";
const TeamStatisticGenerator = () => {
	return (
		<div className='mainWrapper' >
			<div className='container-fluid'>
				<Header />
				<TeamStatisticTable />
				<CreateTeamStatisticModal />
			</div>
		</div>
	)
}
export default TeamStatisticGenerator