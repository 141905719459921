import { setToken, removeToken } from "../../utils/authManager";
import firebase from "../../firebase/config";
export function loginWithPassword(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await firebase
        .auth()
        .signInWithEmailAndPassword(formData.email, formData.password)
      setToken(resp.user.refreshToken)
      resolve(resp)
    } catch (err) {
      reject(err)
    }
  });
}
export function _handleLogout() {
  return new Promise(async (resolve, reject) => {
    firebase.auth().signOut().then(() => {
      removeToken();
      resolve(true)
    }).catch((error) => {
      console.log(error)
      reject(error)
    });
  })
}