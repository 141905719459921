const esESTranslation = {
  'Correct Score': 'Resultado Exacto',
  'Full Time Result': 'Resultado al final',
  '1st Half - 3-Way': '1a Parte – 3 Opciones',
  'Double Chance': 'Doble Oportunidad',
  'Both Teams to Score': 'Ambos equipos marcan',
  'Draw No Bet': 'Resultado sin Empate',
  Handicap: 'Handicap',
  '2nd Half 3-Way': '2a Parte – 3 Opciones',
  '1st Half - Both Teams to Score': '1a Parte – Ambos equipos marcan',
  'First Goalscorer': 'Primer Goleador',
  'Anytime Goalscorer': 'Marca en cualquier momento',
  'Highest Scoring Half': 'Mitad con más goles',
  'Last Goalscorer': 'Ultimo Goleador',
  'Asian Handicap': 'Handicap Asiatico',
  'Score in Both Halves': 'Marca en Ambas Partes',
  'Win Both Halves': 'Gana en ambas partes',
  'Win Either Half': 'Gana cualquier parte',
  'Odd or Even Total': 'Resultado Total Par o Impar',
  'To Win To Nil': 'Ganar a cero',
  'Clean Sheet': 'Porteria a cero',
  'Match Result and Both Teams To Score':
    'Resultado Partido y Ambos Equipos marcan',
  'Half-time Odd or Even Total': 'Resultado Media Parte Par o Impar',
  'Total Goals Over/Under': 'Total de Goles (más / menos)',
  'Half-time Correct Score': 'Resultado en la Media Parte',
  'Match Result and Total Goals Over/Under':
    'Resultado Partido y Total de Goles (más / menos)',
  'Half-time Handicap': 'Handicap a la Media Parte',
  'Hat-trick': 'Hat Trick',
  'Away Team Total Goals Over/Under':
    'Equipo Visitante Total de Goles (más / menos)',
  'Half-time Totals Over/Under':
    'Total de Goles en la media parte (más / menos)',
  'Home Team Total Goals Over/Under':
    'Equipo Local Total de Goles (Más / menos)',
  'Half-time Draw No Bet': '1a Parte – Resultado sin Empate',
  '2nd Half Correct Score': '2a Parte – ResultadoExacto',
  'Half-time/Full-time': 'Media Parte / Final Partido',
  'Total Corners Over/Under': 'Total de Corners Más / Menos',
  'Booking Points Over/Under': 'Puntos de Tarjeta Más / Menos',
  BetBuilder: 'BetBuilder',
  MatchCombo: 'Match Combo',
  'Home Team Total Corners Over/Under':
    'Equipo Local - Total de Corners Más / Menos',
  'Away Team Total Corners Over/Under':
    'Equipo Visitante - Total de Corners Más / Menos',
  'Total Corners 1st Half Over/Under': 'Total de Corners 1a Parte Más / Menos',
  'Total Corners 2nd Half Over/Under': 'Total de Corners 2a Parte Más / Menos',
  'Home Team Booking Points Over/Under':
    'Equipo Local Total Puntos Tarjeta Más o Menos',
  'Away Team Booking Points Over/Under':
    'Equipo Visitante  Total Puntos Tarjeta Más o Menos',
  'Most Corners': 'Mas corners',
  '1st Half - Most Corners': '1a Parte – Mas corners',
  'Player To Be Carded': 'Jugador ve tarjeta',
  'Player To Be Sent Off': 'Jugador expulsado',
  'Player to Have One or More Assists': 'Jugador hace una o mas asistencias',
  'Player to Have Two or More Assists': 'Jugador hace dos  o mas asistencias',
  'Player to Have Three or More Assists': 'Jugador hace tres o mas asistencias',
  'Player to Score from Outside the Box': 'Jugador marca desde fuera del area',
  'Player to Score a Header': 'Jugador marca de cabeza',
  '1st Half - Player to Score from Outside the Box':
    '1a Parte – Jugador marca desde fuera del area',
  '1st Half - Player to Score a Header': '1a Parte – Jugador marca de cabeza',
  'Total shots at goal': 'Total de Disparos',
  'Total shots on target': 'Total de Disparos a Puerta',
  'Total passes': 'Total de Pases',
  'Home Team Total Shots at Goal': 'Equipo Local Total Disparos',
  'Away Team Total Shots at Goal': 'Equipo Visitante Total Disparos',
  'Home Team Total Shots on Target': 'Equipo Local Total  Disparos a Puerta',
  'Away Team Total Shots on Target': 'Equipo Visitante Total Disparos a Puerta',
  'Home Team Total Passes': 'Equipo Local Total Pases',
  'Away Team Total Passes': 'Equipo Visitante Total Pases',
  "oddsTerms": "*Cuotas sujetas a cambios. Con límites de apuesta máxima. Se aplican Términos y Condiciones.",
  "Outright Winner": "Ganador",
  "To Finish In Top 4": "Finalizar en el Top 4",
  "To Be Relegated": "Descenso",
  "Player Top Goalscorer": "Máximo Goleador",
  "Bet Now": "¡APOSTAR!",
  "Bet Expired": "Epuesta Expiró"
}
export default esESTranslation
