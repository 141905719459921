import firebase from "../../firebase/config";
import axios from "axios";
export function _fetchTeamStatsBanner() {
  return new Promise(async (resolve, reject) => {
    try {
      let snapshot = await firebase.firestore()
        .collection("TeamStats")
        .get();
      let list = [];
      snapshot.forEach((documentSnapshot) => {
        list.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        })
      });
      resolve(list);
    } catch (err) {
      reject(err)
    }
  });
}
export function _createTeamStatsBanner(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.firestore()
        .collection("TeamStats")
        .add({
          ...formData,
          createdOn: Date.now()
        })
        resolve(true);
    } catch (err) {
      reject(err)
    }
  });
}
export function _deleteTeamStatsBanner(key) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.firestore()
        .collection("TeamStats")
        .doc(key).delete()
        resolve(true);
    } catch (err) {
      reject(err)
    }
  });
}
export function _updateTeamStatsBanner(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.firestore()
        .collection("TeamStats")
        .doc(formData.key)
        .update(formData)
      resolve(true);
    } catch (err) {
      reject(err)
    }
  });
}
export function fetchFootballAPIInfo(endpoint, params = null) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${process.env.REACT_APP_FOOTBALL_REST_ENDPOINT}${endpoint}`
      if(params){
        const u = new URLSearchParams(params).toString();
        url +=`?${u}`
      }
      const resp = await axios.get(url, {
        headers: {
          "x-rapidapi-host": process.env.REACT_APP_FOOTBALL_HOST,
          "x-rapidapi-key": process.env.REACT_APP_FOOTBALL_API_KEY
        }
      })
      resolve(resp.data)
    } catch (err) {
      reject(err)
    }
  });
}