
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  persistStore,
  persistReducer,
  // autoRehydrate
}
  from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage'

// Reducers imported
import { authReducer } from "./auth/reducers";
import { bucketReducer } from "./bucket/reducers";
import { spectateReducer } from "./spectate/reducers";
import { netWorthReducer } from "./netWorth/reducers";
import { teamStatReducer } from "./teamStats/reducers";
import { predictionBannerReducer } from "./predictionBanners/reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  bucket: bucketReducer,
  spectate: spectateReducer,
  netWorth: netWorthReducer,
  teamStats: teamStatReducer,
  predictionBanners: predictionBannerReducer
});

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method
  storage,
  // Merge two-levels deep.
  stateReconciler: autoMergeLevel2,
  // Whitelist (Save Specific Reducers)
  whitelist: ['bucket'],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);
			// eslint-disable-next-line
export default () => {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  let store = createStore(persistedReducer, composeWithDevTools(middleWareEnhancer))
  let persistor = persistStore(store)
  return { store, persistor }
}
