import daDKTranslation from "../locales/da_DK"
import deDETranslation from "../locales/de_DE"
import svSETranslation from "../locales/sv_SE"
import esESTranslation from "../locales/es_ES"
import roROTranslation from "../locales/ro_RO"
import enUSTranslation from "../locales/en_US"
import enCATranslation from "../locales/en_US"


const GetMarketName = (marketName, language) => {
  let _marketName = ""
  if (language === "da_DK") {
    if (daDKTranslation[marketName]) {
      _marketName = daDKTranslation[marketName]
    }
  } else if (language === "de_DE") {
    if(marketName){
      const marketNameSplits = marketName.split(" v ")
      if(marketNameSplits.length === 2){
        _marketName = `${deDETranslation[marketNameSplits[0]] ?? marketNameSplits[0]} v ${deDETranslation[marketNameSplits[1]] ?? marketNameSplits[1]}`
      }else if (deDETranslation[marketName]) {
        _marketName = deDETranslation[marketName]
      }
    }
  } else if (language === "sv_SE" || language === "sv_Mr_Green") {
    if (svSETranslation[marketName]) {
      _marketName = svSETranslation[marketName]
    }
  } else if (language === "es_ES") {
    if (esESTranslation[marketName]) {
      _marketName = esESTranslation[marketName]
    }
  } else if (language === "ro_RO") {
    if (roROTranslation[marketName]) {
      _marketName = roROTranslation[marketName]
    }
  } else if (language === "en_US") {
    if (enUSTranslation[marketName]) {
      _marketName = enUSTranslation[marketName]
    }
  }
  else if (language === "en_CA") {
    if (enCATranslation[marketName]) {
      _marketName = enCATranslation[marketName]
    }
  }
  if (_marketName.length) {
    return _marketName
  } else {
    return marketName
  }
}
export default GetMarketName
