const enUSTranslation = {
  "3-Way": "Full Time Result",
  "oddsTerms": "*Odds subject to change. Max stakes applies. T&C apply.",
  "Bet Now": "BET HERE",
  "Bet Expired": "EXPIRED",
  "Strength": "Strength",
  "Attacking Potential": "Attacking Potential",
  "Defensive Potential": "Defensive Potential",
  "Poisson Distribussion": "Poisson Distribussion",
  "Strength H2H": "Strength H2H",
  "Goals H2H": "Goals H2H",
  "Wins the Game": "Wins the Game"
};
  
  export default enUSTranslation;
  