import {
  TEAM_STATS_LOADING,
  TEAM_STATS_SET_DATA,
  TEAM_STATS_SET_LANGUAGE,
  TEAM_STATS_SET_DETAIL
} from "./constants";

const initialState = {
  teamStats: [],
  loading: false,
  language: "en",
  teamStat: null
};

export function teamStatReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case TEAM_STATS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case TEAM_STATS_SET_DATA:
      return {
        ...state,
        teamStats: action.payload
      };
    case TEAM_STATS_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case TEAM_STATS_SET_DETAIL:
      return {
        ...state,
        teamStat: action.payload
      };
    default:
      return state;
  }
}
