import React, { useState } from 'react'
import GetMarketName from '../../utils/GetMarketName'
import getEventName from '../../utils/GetEventName'
import { connect } from "react-redux";
const SpectateBetCard = props => {
  const [showFullList, setShowFullList] = useState(false) 
  const renderShowAllButton = () => {
    let count = props.betOffer?.length
    if (count > 5) {
      return (
        <div
          className='col-4 col-lg-1 pr-2'
          onClick={() => {
            setShowFullList(!showFullList)
          }}
        >
          <div className='btn btn-outline-seconday btn-sm'>Show List</div>
        </div>
      )
    }
  }

  const Card = ({ outcome, index }) => {
    return (
      <div className={`col-12 col-lg-6`} key={index}>
        <div
          data-toggle='modal'
          data-target='#exampleModalLong'
          onClick={() => {
            props.setSelectedBet({
              id: outcome.id,
              betOfferId: outcome.id,
              oddsFractional: outcome.fractionPrice,
              oddsAmerican: outcome.americanPrice,
              price: outcome.price,
              title: outcome.sport === "ANTEPOST_RACING" ? "Winner Market" : GetMarketName(props.market?.name, props.lang),
							betName: outcome.sport === "ANTEPOST_RACING" ? "" : getEventName(outcome.name, null, {}, props.lang),
							eventName: outcome.sport === "ANTEPOST_RACING" ? getEventName(outcome.name, null, {}, props.lang) : props.event?.name,
              eventId: props.event?.id,
              event: props.event,
              market: props.market
            })
          }}
          className='w-100'
        >
          <div className='row btn-accordian-inner'>
            <div className='col-4'>{getEventName(outcome.name, null, {}, props.lang)}</div>
            <div className='col-3'>{outcome.fractionPrice}</div>
            <div className='col-3'>{outcome.price}</div>
            <div className='col-2'>{outcome.americanPrice}</div>
          </div>
        </div>
      </div>
    )
  }

  const renderCards = () => {
    let count = props.betOffer?.length
    let jsx = []
    if (count > 5) {
      if (showFullList) { 
        props.betOffer.map((bet, index) => { // eslint-disable-line
          jsx.push(<Card outcome={bet?.attributes} index={index} key={index} />)
        })
      } else {
        let i = 0
        props.betOffer.map((bet, index) => { // eslint-disable-line
          if (i < 5) {
            jsx.push(<Card outcome={bet?.attributes} index={index} key={index} />)
          }
          i++
        })
      }
    } else {
      props.betOffer.map((bet, index) =>
        jsx.push(<Card outcome={bet?.attributes} index={index} key={index} />)
      )
    }
    return jsx
  }
  return (
    <div className={``}>
      {props.length > 0 && (
        <>
          <div className='row mt-3 mb-2 '>
            <div className='col-8 col-lg-11 pl-lg-4'>
              <h6 className='font-weight-bold'>
                {GetMarketName(props.market?.name, props.lang)}
              </h6>
            </div>
            {renderShowAllButton()}
          </div>
          <div className='row'>
            {renderCards()}
            <div className={`col-12 d-flex`}>
              <div
                className='w-100'
                style={{
                  height: 0.5,
                  backgroundColor: '#0003'
                }}
              ></div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  lang: state.spectate.language
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SpectateBetCard);