//constants
import translations from "../../constants/teamStatsTranslations";
import deDETranslation from "../../locales/de_DE";
//services
import { useEffect, useState } from "react";
import { fetchFootballAPIInfo } from "../../store/teamStats/services";
//redux
import { useSelector } from "react-redux";

const useGenerateTeamStatsBanner = (data) => {
  const { language } = useSelector((state) => state.teamStats);
  const [teamStats, setTeamStats] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const handleFetchTeamStats = (leagueId, teamId, season) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await fetchFootballAPIInfo("teams/statistics", {
          league: leagueId,
          season,
          team: teamId,
        });
        resolve(resp?.response);
      } catch (err) {
        reject(err);
      }
    });
  };
  const getFixtureData = (key, _teamStats) => {
    const {
      away: first_away,
      home: first_home,
      total: first_all,
    } = _teamStats[0]?.fixtures?.[key];
    const {
      away: second_away,
      home: second_home,
      total: second_all,
    } = _teamStats[1]?.fixtures?.[key];
    return {
      first_away,
      first_home,
      first_all,
      second_away,
      second_home,
      second_all,
    };
  };
  const getGoalsData = (key, _teamStats) => {
    const _keys = key.split(".");
    const {
      away: first_away,
      home: first_home,
      total: first_all,
    } = _teamStats[0]?.goals?.[_keys[0]]?.[_keys[1]];
    const {
      away: second_away,
      home: second_home,
      total: second_all,
    } = _teamStats[1]?.goals?.[_keys[0]]?.[_keys[1]];
    return {
      first_away,
      first_home,
      first_all,
      second_away,
      second_home,
      second_all,
    };
  };
  const getEmptyData = () => {
    return {
      first_home: "",
      first_away: "",
      first_all: "",
      second_home: "",
      second_away: "",
      second_all: "",
    };
  };
  useEffect(() => {
    (async () => {
      if (
        data?.leagueId &&
        data?.firstTeamId &&
        data?.season &&
        data?.secondTeamId
      ) {
        let resp = await Promise.all([
          handleFetchTeamStats(data.leagueId, data.firstTeamId, data.season),
          handleFetchTeamStats(data.leagueId, data.secondTeamId, data.season),
        ]);
        if (language === "de" && resp.length === 2) {
          resp[0].team.name =
            deDETranslation[resp[0].team.name] ?? resp[0].team.name;
          resp[1].team.name =
            deDETranslation[resp[1].team.name] ?? resp[1].team.name;
        }
        setTeamStats(resp);
      }
    })();
  }, [data, language]);
  useEffect(() => {
    if (Array.isArray(teamStats) && teamStats?.length === 2) {
      const _tableRows = [];
      [
        translations.gamesPlayed[language],
        translations.wins[language],
        translations.draws[language],
        translations.loss[language],
        translations.goalsFor[language],
        translations.goalsAgainst[language],
        // "Goals Average",
        // "Goals",
        // "Goals For",
        // "Goals Against"
      ].map((item, i) => {
        if (i === 0) {
          _tableRows.push({
            category: item,
            ...getFixtureData("played", teamStats),
          });
        } else if (i === 1) {
          _tableRows.push({
            category: item,
            ...getFixtureData("wins", teamStats),
          });
        } else if (i === 2) {
          _tableRows.push({
            category: item,
            ...getFixtureData("draws", teamStats),
          });
        } else if (i === 3) {
          _tableRows.push({
            category: item,
            ...getFixtureData("loses", teamStats),
          });
        } else if (i === 4) {
          _tableRows.push({
            category: item,
            ...getGoalsData("for.total", teamStats),
          });
        } else if (i === 5) {
          _tableRows.push({
            category: item,
            ...getGoalsData("against.total", teamStats),
          });
        }
        // else if (i === 4) {
        // 	_tableRows.push({ category: item, ...getEmptyData() })
        // else if (i === 7) {
        // 	_tableRows.push({ category: item, ...getEmptyData() })
        // } else if (i === 8) {
        // 	_tableRows.push({ category: item, ...getGoalsData("for.average", teamStats) })
        // } else if (i === 9) {
        // 	_tableRows.push({ category: item, ...getGoalsData("against.average", teamStats) })
        // }
      });
      setTableRows(_tableRows);
    }
  }, [teamStats]);
  return {
    teamStats,
    tableRows,
  };
};
export default useGenerateTeamStatsBanner;
