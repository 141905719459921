import axios from "axios";
export function _fetchEvents(link) {
  return new Promise(async (resolve, reject) => {
    try {
      axios
      .get(link)
      .then((resp) =>{
        resolve(resp.data)
      })
    } catch (err) {
      reject(err)
    }
  });
}