import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import $ from "jquery"
//redux
import { connect } from "react-redux";
import {netWorthSetDetails, updateNetWorth} from "../../store/netWorth/actions";
const EditNetWorthModal = (props) => { // eslint-disable-next-line
	const [state, setState] = useState({
		name: "",
		yearlyIncome: "",
	})
	const [btnLoading, setBtnLoading] = useState(false);
	const handleUpdate = async(e) =>{
		try{
			e.preventDefault();
			setBtnLoading(true);
			await props.updateNetWorth({
				key: props.netWorth?.key,
				name: state.name,
				yearlyIncome: state.yearlyIncome
			});
			$("#editNetWorthModal").modal("hide");
			$('.modal-backdrop').remove();
			Swal.fire("Updated successfully", "", "success")
		}catch(err){
			Swal.fire("Something went wrong", "", "error")
		}finally{
			setBtnLoading(false);
		}
	}
	useEffect(()=>{
		if(props.netWorth?.key){
			setState({
				...state,
				name: props.netWorth.name,
				yearlyIncome: props.netWorth.yearlyIncome
			})
		}         // eslint-disable-next-line
	}, [props.netWorth])
	return (
		<div className="modal fade" id="editNetWorthModal" tabindex="-1" role="dialog" aria-labelledby="editNetWorthModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="editNetWorthModalLabel">Edit net worth banner</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<form onSubmit={handleUpdate}>
						<div className="modal-body" style={{justifyContent: "space-around"}}>
							<div className="form-group">
								<label htmlFor="net_worth_name">Name</label>
								<input 
									type="text" 
									className="form-control" 
									id="net_worth_name" 
									placeholder="Enter name" 
									required
									value={state.name}
									onChange={(e)=>setState({...state, name: e.target.value})}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="net_worth_income">Annual income</label>
								<input 
									type="number" 
									className="form-control" 
									id="net_worth_income" 
									placeholder="Enter yearly income" 
									required
									value={state.yearlyIncome}
									onChange={(e)=>setState({...state, yearlyIncome: e.target.value})}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button 
								type="submit" 
								className="btn btn-primary"
								disabled={btnLoading}
							>
								{btnLoading ? "..." : "Update Banner"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({
	netWorth: state.netWorth.netWorth
});
const mapDispatchToProps = {
	netWorthSetDetails,
	updateNetWorth
};
export default connect(mapStateToProps, mapDispatchToProps)(EditNetWorthModal);