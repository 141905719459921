import React from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
import { predictionSetLanguage } from "../../store/predictionBanners/actions";
//constants
import languages from "../../constants/languages";
//components
import SelectPicker from "../common/formFields/SelectPicker";
import Button from "../common/formFields/Button";

const PredictionsHeader = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.predictionBanners);
  return (
    <div className="row">
      <div className="col-md-12 my-5">
        <div className="col-md-12 bg-dark shadow-sm rounded">
          <form
            className="form-cust"
            style={{ justifyContent: "space-between" }}
          >
            <SelectPicker
              label={""}
              value={language}
              className="top-input-drop"
              onChange={(e) => dispatch(predictionSetLanguage(e.target.value))}
              options={languages.filter(
                (lang) => !["da", "sv"].includes(lang.value)
              )}
            />
            <Button
              label="Create New Banner"
              type="button"
              className="btn btn-primary top-btn"
              data-toggle="modal"
              data-target="#createPredictionsModal"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
export default PredictionsHeader;
