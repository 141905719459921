import {
  TEAM_STATS_LOADING,
  TEAM_STATS_SET_DATA,
  TEAM_STATS_SET_LANGUAGE,
  TEAM_STATS_SET_DETAIL
} from "./constants";
import { 
  _fetchTeamStatsBanner, 
  _createTeamStatsBanner, 
  _deleteTeamStatsBanner, 
  _updateTeamStatsBanner 
} from "./services";

export function teamStatsSetLoading(loading) {
  return {
    type: TEAM_STATS_LOADING,
    payload: loading,
  };
}

export function teamStatsSetData(data) {
  return {
    type: TEAM_STATS_SET_DATA,
    payload: data,
  };
}

export function teamStatsSetLanguage(data) {
  return {
    type: TEAM_STATS_SET_LANGUAGE,
    payload: data,
  };
}
export function teamStatsSetDetail(data) {
  return {
    type: TEAM_STATS_SET_DETAIL,
    payload: data,
  };
}
export const fetchTeamStatsBanners = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(teamStatsSetLoading(true));
    _fetchTeamStatsBanner().then(async (res) => {
      dispatch(teamStatsSetData(res))
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(teamStatsSetLoading(false));
    })
  })
};
export const createTeamStatsBanner = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(teamStatsSetLoading(true));
    _createTeamStatsBanner(formData).then(async (res) => {
      await dispatch(fetchTeamStatsBanners());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(teamStatsSetLoading(false));
    })
  })
};
export const deleteTeamStatsBanner = (key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(teamStatsSetLoading(true));
    _deleteTeamStatsBanner(key).then(async (res) => {
      await dispatch(fetchTeamStatsBanners());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(teamStatsSetLoading(false));
    })
  })
};
export const updateTeamStatsBanner = (formData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(teamStatsSetLoading(true));
    _updateTeamStatsBanner(formData).then(async (res) => {
      await dispatch(fetchTeamStatsBanners());
      resolve(res)
    }).catch((err) => {
      reject(err)
    }).finally(() => {
      dispatch(teamStatsSetLoading(false));
    })
  })
};