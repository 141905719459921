const languages = [{
    label: "English",
    value: "en",
}, {
    label: "Spanish",
    value: "es",
}, {
    label: "German",
    value: "de",
}, {
    label: "Italian",
    value: "it",
}, {
    label: "Danish",
    value: "da",
}, {
    label: "Swedish",
    value: "sv",
}, {
    label: "Romanian",
    value: "ro",
}];
export default languages