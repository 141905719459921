import React, { useEffect, useMemo, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
//components
import SelectPicker from "../common/formFields/SelectPicker";
import Button from "../common/formFields/Button";
import ModalWrapper from "../common/ModalWrapper";
import PredictionsBanner from "./PredictionsBanner";

//hooks
import useCreatePredictionBanner from "../../hooks/predictionBanners/useCreatePredictionBanner";
import useGeneratePredictionBanner from "../../hooks/predictionBanners/useGeneratePredictionBanner";

//redux
import { useDispatch, useSelector } from "react-redux";
import { predictionSetDetail } from "../../store/predictionBanners/actions";

//util
import { generatePredictionBannerHTML } from "../../utils/GeneratePredictionBannerHTML";
//constants
import { PREDICTION_DATA_POINTS } from "../../constants/predictions";

const CreatePredictionsModal = (props) => {
  const [bannerTitle, setBannerTitle] = useState("");
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.predictionBanners);
  const {
    countries,
    leagues,
    state,
    btnLoading,
    handleSetData,
    handleSaveBanner,
  } = useCreatePredictionBanner();

  const { fixtures, predictionComparisons } =
    useGeneratePredictionBanner(state);
  const statData = useMemo(
    () =>
      predictionComparisons !== null
        ? PREDICTION_DATA_POINTS(language).map((item) => ({
            ...item,
            home: predictionComparisons[item.value]?.home ?? "0%",
            away: predictionComparisons[item.value]?.away ?? "0%",
          }))
        : [],
    [predictionComparisons]
  );
  const chartData = useMemo(() => {
    return {
      labels: statData.map((data) => data.label),
      datasets: [
        {
          label: state.firstTeamName,
          data: statData.map((data) => parseFloat(data.home.split("%")[0])),
          backgroundColor: "rgba(253, 99, 1, 0.6)",
          borderColor: "rgba(253, 99, 1, 1)",
          borderWidth: 1,
        },
        {
          label: state.secondTeamName,
          data: statData.map((data) => parseFloat(data.away.split("%")[0])),
          backgroundColor: "rgba(52, 58, 64, 0.5)",
          borderColor: "rgba(52, 58, 64, 1)",
          borderWidth: 1,
        },
      ],
    };
  }, [statData, state, predictionComparisons]);

  const handleCopyBanner = () => {
    navigator.clipboard.writeText(
      generatePredictionBannerHTML({
        statData,
        chartData,
        bannerTitle,
      })
    );
    toast.success("Banner copied to clipboard successfully!");
  };
  useEffect(() => {
    $("#createPredictionsModal").on("hidden.bs.modal", function (e) {
      dispatch(predictionSetDetail(null));
    });
  }, []);
  return (
    <ModalWrapper
      title={"Build predictions banner"}
      id={"createPredictionsModal"}
      titleId={"createPredictionsModalLabel"}
      size="xl"
    >
      <div className="modal-description">
        <p>* Select the fixture you want to create a predictions banner for.</p>
        <p>* You can only find future matches</p>
      </div>
      <form onSubmit={handleSaveBanner}>
        <div className="modal-body flex-column">
          <div className="d-flex w-100 justify-content-between">
            <SelectPicker
              label={"Country"}
              onChange={handleSetData}
              value={state.country}
              options={countries}
              className="top-input-dark"
              name="country"
              style={{ width: "100%" }}
            />
            <SelectPicker
              label={"League"}
              onChange={handleSetData}
              value={state.leagueId}
              options={leagues}
              className="top-input-dark"
              name="leagueId"
              style={{ width: "100%" }}
              disabled={!state.country}
            />
            {/* <SelectPicker
              label={"Home Team"}
              onChange={handleSetData}
              value={state.firstTeamId}
              options={teams}
              className="top-input-dark"
              name="firstTeamId"
              style={{ width: "100%" }}
              disabled={!state.leagueId}
            />
            <SelectPicker
              label={"Away Team"}
              onChange={handleSetData}
              value={state.secondTeamId}
              options={teams}
              className="top-input-dark"
              name="secondTeamId"
              style={{ width: "100%" }}
              disabled={!state.leagueId}
            /> 
            <SelectPicker
              label={"Fixture"}
              onChange={handleSetData}
              value={state.fixtureId}
              options={fixtures}
              className="top-input-dark"
              name="fixtureId"
              style={{ width: "100%" }}
              disabled={!state.firstTeamId && !state.secondTeamId}
            />*/}
            <SelectPicker
              label={"Events"}
              onChange={(e) => handleSetData(e, fixtures)}
              value={state.fixtureId}
              options={fixtures}
              className="top-input-dark"
              name="fixtureId"
              style={{ width: "100%" }}
              disabled={!state.leagueId}
            />
          </div>
          {state.fixtureId && (
            <div className="w-100 mt-4">
              <PredictionsBanner
                statData={statData}
                chartData={chartData}
                bannerTitle={bannerTitle}
              />
            </div>
          )}
          <div className="form-group mt-5 w-100 px-3">
            <input
              type="text"
              className="form-control"
              id="prediction_banner_title"
              placeholder="Enter Banner Title"
              value={bannerTitle}
              onChange={(e) => setBannerTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          {state.fixtureId && (
            <Button
              label="Copy Banner"
              type="button"
              onClick={handleCopyBanner}
            />
          )}
          <Button isLoading={btnLoading} label="Save Banner" type="submit" />
        </div>
      </form>
    </ModalWrapper>
  );
};
export default CreatePredictionsModal;
