import React from 'react'
import './Switch.css'
const Switch = props => {
  return (
    <div className='button-switch'>
      <input
        type='checkbox'
        id='switch-blue'
        className='switch'
        checked={props.selected === props.labels[0] ? false : true}
        onChange={e => props.onChange()}
      />
      <label htmlFor='switch-blue' className='lbl-off'>
        {props.labels[0]}
      </label>
      <label htmlFor='switch-blue' className='lbl-on'>
        {props.labels[1]}
      </label>
    </div>
  )
}
export default Switch
