const SelectPicker = ({
	label,
	onChange = () => { },
	value,
	options = [],
	className = "top-input-drop",
	...props
}) => {
	return (
		<select
			className={className}
			style={{ maxWidth: 150, marginRight: 15 }}
			onChange={onChange}
			value={value}
			{...props}
		>
			<option value="" disabled selected>
				{label}
			</option>
			{options.map((option, i) => (
				<option
					value={option.value}
					className="bg-dark"
					key={`${option.value}-${i}`}
				>
					{option.label}
				</option>
			))}
		</select>
	)
}
export default SelectPicker