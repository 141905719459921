import React, { useState } from 'react'
import getEventName from '../utils/GetEventName'
var odds = require('odds-converter')

const BetCards = props => {
  const [showFullList, setShowFullList] = useState(false)
  const getCount = () => {
    let count = 0 // eslint-disable-next-line
    props.betOffer.map(bet => {
      // eslint-disable-next-line
      bet.outcomes.map(outcome => {
        count++
      })
    })
    return count
  }
  const renderShowAllButton = () => {
    let count = getCount()
    if (count > 5) {
      return (
        <div
          className='col-4 col-lg-1 pr-2'
          onClick={() => {
            setShowFullList(!showFullList)
          }}
        >
          <div className='btn btn-outline-seconday btn-sm'>Show List</div>
        </div>
      )
    }
  }
  const Card = ({ outcome, index }) => {
    return (
      <div className={`col-12 col-lg-6`} key={index}>
        <div
          data-toggle='modal'
          data-target='#exampleModalLong'
          onClick={() => {
            console.log(outcome)
            props.setSelectedBet({
              id: outcome.id,
              betOfferId: outcome.betOfferId,
              oddsFractional: outcome.oddsFractional,
              oddsAmerican: outcome.oddsAmerican,
              title: props.betOffer[0]?.criterion.label,
              betName: getEventName(
                outcome.participant
                  ? outcome.participant
                  : outcome.englishLabel,
                outcome,
                props.event,
                props.lang
              ),
              eventName: props.event?.name,
              eventId: props.event?.id,
              event: props.event
            })
          }}
          className='w-100'
        >
          <div className='row btn-accordian-inner'>
            <div className='col-4'>
              {getEventName(
                outcome.participant
                  ? outcome.participant
                  : outcome.englishLabel,
                outcome,
                props.event,
                props.lang
              )}
            </div>
            <div className='col-4'>{outcome.oddsFractional}</div>
            <div className='col-4'>
              {parseFloat(
                odds.american.toDecimal(outcome.oddsAmerican)
              ).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderCards = () => {
    let count = getCount()
    let jsx = []
    if (count > 5) {
      if (showFullList) {
        // eslint-disable-next-line
        props.betOffer.map(bet => {
          // eslint-disable-next-line
          bet.outcomes.map((outcome, index) => {
            // eslint-disable-next-line
            jsx.push(<Card outcome={outcome} index={index} />)
          })
        })
      } else {
        let i = 0 // eslint-disable-next-line
        props.betOffer.map(bet => {
          // eslint-disable-next-line
          bet.outcomes.map((outcome, index) => {
            if (i < 5) {
              jsx.push(<Card outcome={outcome} index={index} />)
            }
            i++
          })
        })
      }
    } else {
      props.betOffer.map(bet =>
        bet.outcomes.map((outcome, index) =>
          jsx.push(<Card outcome={outcome} index={index} />)
        )
      )
    }
    return jsx
  }
  return (
    <div className={``}>
      {props.length > 0 && (
        <>
          <div className='row mt-3 mb-2 '>
            <div className='col-8 col-lg-11 pl-lg-4'>
              <h6 className='font-weight-bold'>
                {props.betOffer[0]?.criterion.label}
              </h6>
            </div>
            {renderShowAllButton()}
          </div>
          <div className='row'>
            {renderCards()}
            <div className={`col-12 d-flex`}>
              <div
                className='w-100'
                style={{
                  height: 0.5,
                  backgroundColor: '#0003'
                }}
              ></div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default BetCards
