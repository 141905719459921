import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function PredictionsBanner({ statData, chartData }) {
  return (
    <div className="d-flex justify-content-between">
      <div style={{ width: "60%", height: 450 }}>
        <Radar data={chartData} style={{ width: "85%", height: "100%" }} />
      </div>
      <div style={{ width: "40%" }}>
        {statData.map((item) => (
          <div className="my-3 text-center" key={item.value}>
            <b>{item.label}</b>
            <div className="d-flex justify-content-center align-items-center mt-2">
              <p className="mr-2">{item.home}</p>
              <div className="progress flex-row-reverse w-50 rounded-0">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={item.home}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: item.home,
                    background: "rgba(253, 99, 1, 1)",
                  }}
                ></div>
              </div>
              <div className="progress w-50 rounded-0">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={item.away}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: item.away,
                    background: "rgba(52, 58, 64, 0.5)",
                  }}
                ></div>
              </div>
              <p className="ml-2">{item.away}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
