export const generatePredictionBannerHTML = ({ statData, chartData, bannerTitle }) => {
  let time = Date.now();
  return `<style>.oddin-predic-title {font-size: 1.5rem;text-align: center;font-family: "888 bold";}.progress {display: flex;height: 1rem;overflow: hidden;line-height: 0;font-size: .75rem;background-color: #e9ecef;border-radius: 0.25rem;}.progress-bar {display: flex;flex-direction: column;justify-content: center;overflow: hidden;color: #fff;text-align: center;white-space: nowrap;background-color: rgba(253, 99, 1, 1);transition: width .6s ease;}.oddin-predic-main {display: flex;justify-content: space-between;align-items: center;max-height: fit-content;}.oddin-canvas {width: 60%;}.oddin-stats {width: 40%;}.oddin-stat {margin-bottom: 1rem;text-align: center;}.oddin-stat-inner {display: flex;justify-content: center;align-items: center;margin-top: 0.5rem;}.oddin-stat-inner-percent-left {margin-right: 0.5rem;}.oddin-stat-inner-percent-right {margin-left: 0.5rem;}@media screen and (max-width: 500px) {.oddin-predic-main {flex-direction: column;}.oddin-predic-main > div {width: 100%;margin-right: 0;margin-bottom: 10px;}}</style>
<script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
<div class="oddin-predic-title">
                    ${bannerTitle}
                </div>
<div class="oddin-predic-main">
<div class="oddin-canvas">
        <canvas id="oddin-prediction-radar-${time}"></canvas>
</div>
      <div class="oddin-stats">
        ${statData
          .map(
            (item) =>
              `<div class="oddin-stat">
            <b>${item.label}</b>
            <div class="oddin-stat-inner">
            <div class="oddin-stat-inner-percent-left">${item.home}</div>
              <div class="progress" style="flex-direction: row-reverse;border-radius: 0;width: 50%">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="item.home"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: ${item.home};background: rgba(253, 99, 1, 1)"
                ></div>
              </div>
              <div class="progress" style="border-radius: 0;width: 50%">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow='${item.away}'
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: ${item.away};background: rgba(52, 58, 64, 0.5)"
                ></div>
              </div>
              <div class="oddin-stat-inner-percent-right">${item.away}</div>
            </div>
          </div>`
          )
          .join("")}
      </div>
    </div>
    <script>
      new Chart(document.getElementById('oddin-prediction-radar-${time}'), {
        type: 'radar',
        data: ${JSON.stringify(chartData)},
        options: {
          elements: {
            line: {
              borderWidth: 3
            }
          }
        },
      });
    </script>
    `;
};
