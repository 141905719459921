
const TOKEN_KEY = 'KAMBI_ODDS_TOKEN';
// const ROLE_KEY = 'KAMBI_ODDS_ROLE'; // to be implemented with used id functions

export const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
}

export const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}