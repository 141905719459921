import roROTranslation from "../locales/ro_RO";
import esESTranslation from "../locales/es_ES";
import enUSTranslation from "../locales/en_US";
import itITTranslation from "../locales/it_IT";
import deDETranslation from "../locales/de_DE";
import enCATranslation from "../locales/en_US";

const getOddsTermsText = (lang) => {
    if(lang === "ro_RO"){
        return roROTranslation.oddsTerms
    }else if(lang === "es_ES"){
        return esESTranslation.oddsTerms
    }else if(lang === "it_IT"){
        return itITTranslation.oddsTerms
    }else if(lang === "de_DE"){
        return deDETranslation.oddsTerms
    }else if(lang === "en_CA"){
        return enCATranslation.oddsTerms
    }else{
        return enUSTranslation.oddsTerms
    }
}
export default getOddsTermsText