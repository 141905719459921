const svSETranslation = {
  "3-Way": "FULLTIDSRESULTAT",
  "Both Teams to Score": "BÅDA LAGEN GÖR MÅL",
  "Draw No Bet": "OAVGJORT INGET SPEL",
  "Total Goals Over/Under": "TOTALT ANTAL MÅL, ÖVER/UNDER",
  "Double Chance": "DUBBELCHANS",
  "Correct Score" : "Korrekt Resultat",
  "Full Time Result" : "Heltidsresultat",
  "1st Half - 3-Way" : "1:a Halvlek - 3-vägs",
  "Handicap" : "Handikapp",
  "2nd Half 3-Way" : "2:a Halvlek - 3-vägs",
  "1st Half - Both Teams to Score" : "1:a Halvlek - Båda Lagen Gör Mål",
  "First Goalscorer" : "Första Målskytt",
  "Anytime Goalscorer" : "Anytime Målskytt",
  "Highest Scoring Half" : "Halvlek med Flest Mål",
  "Last Goalscorer" : "Sista Målskytt",
  "Asian Handicap" : "Asian Handikapp",
  "Score in Both Halves" : "Mål i Båda Halvlekarna",
  "Win Both Halves" : "Vinna Båda Halvlekarna",
  "Win Either Half" : "Vinna en av halvlekarna",
  "Odd or Even Total" : "Antal Mål Udda eller Jämnt",
  "To Win To Nil" : "Vinst och hållen nolla",
  "Clean Sheet" : "Hållen nolla",
  "Match Result and Both Teams To Score" : "Matchresultat och Båda Lagen Gör Mål",
  "Half-time Odd or Even Total" : "Halvtid Antal Mål Udda eller Jämnt",
  "Half-time Correct Score" : "Halvtid  Korrekt Resultat",
  "Match Result and Total Goals Over/Under" : "Matchresultat och Antal Mål Över/Under",
  "Half-time Handicap" : "Halvtid Handikapp",
  "Hat-trick" : "Hat-trick",
  "Away Team Total Goals Over/Under" : "Bortalag Antal Mål Över/Under",
  "Half-time Totals Over/Under" : "Halvtid  Antal Mål Över/Under",
  "Home Team Total Goals Over/Under" : "Hemmalag Antal Mål Över/Under",
  "Half-time Draw No Bet" : "Halvtid Oavgjort-inget spel",
  "2nd Half Correct Score" : "Andra Halvlek Korrekt Resultat",
  "Half-time/Full-time" : "Halvtid/Fulltid",
  "Total Corners Over/Under" : "Antal Hörnor Över/Under",
  "Booking Points Over/Under" : "Varningspoäng Över/Under",
  "BetBuilder" : "BetBuilder",
  "MatchCombo" : "MatchCombo",
  "Home Team Total Corners Over/Under" : "Hemmalag Antal Hörnor Över/Under",
  "Away Team Total Corners Over/Under" : "Bortalag Antal Hörnor Över/Under",
  "Total Corners 1st Half Over/Under" : "Antal Hörnor Första Halvlek Över/Under",
  "Total Corners 2nd Half Over/Under" : "Antal Hörnor Andra Halvlek Över/Under",
  "Home Team Booking Points Over/Under" : "Hemmalag Varningspoäng Över/Under",
  "Away Team Booking Points Over/Under" : "Bortalag Varningspoäng Över/Under",
  "Most Corners" : "Flest Hörnor",
  "1st Half - Most Corners" : "1:a Halvlek - Flest Hörnor",
  "Player To Be Carded" : "Spelare får Gult Kort",
  "Player To Be Sent Off" : "Spelare får Rött Kort",
  "Player to Have One or More Assists" : "Spelare med En eller Fler Assist",
  "Player to Have Two or More Assists" : "Spelare med Två eller Fler Assist",
  "Player to Have Three or More Assists" : "Spelare med Tre eller Fler Assist",
  "Player to Score from Outside the Box" : "Spelare med Mål Utanför Straffområdet",
  "Player to Score a Header" : "Spelare med Nickmål",
  "1st Half - Player to Score from Outside the Box" : "1:a Halvlek - Spelare med Mål Utanför Straffområdet",
  "1st Half - Player to Score a Header" : "1:a Halvlek - Spelare med Nickmål",
  "Total shots at goal" : "Antal Skott",
  "Total shots on target" : "Antal Skott På Mål",
  "Total passes" : "Antal Passningar",
  "Home Team Total Shots at Goal" : "Hemmalag Antal Skott",
  "Away Team Total Shots at Goal" : "Bortalag Antal Skott",
  "Home Team Total Shots on Target" : "Hemmalag Antal Skott På Mål",
  "Away Team Total Shots on Target" : "Bortalag Antal Skott På Mål",
  "Home Team Total Passes" : "Hemmalag Antal Passningar",
  "Away Team Total Passes" : "Bortalag Antal Passningar",
  "Outright Winner": "Outright Vinnare",
  "To Finish In Top 4": "Top 4 Placering",
  "To Be Relegated": "Nedflyttning",
  "Player Top Goalscorer": "Spelare Flest Mål",
  "Bet Now": "GÅ MED!",
  "Bet Expired": "utgången insats"
  };
  
  export default svSETranslation;
  