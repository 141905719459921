import React, { useState } from "react";
import { GenerateComboBetHTML } from "../utils/GenerateComboBetHTML";
import { GenerateSpectateComboBetHTML } from "../utils/GenerateSpectateComboBetHTML";
import ComboBetBanner from "../components/ComboBetBanner/ComboBetBanner";

import { connect } from "react-redux";

const SvgComboModal = (props) => {
	const [bannerTitle, setBannerTitle] = useState('')
	const handleCopyToClickboard = () => {
		if (props.apiType === "kambi") {
			navigator.clipboard.writeText(GenerateComboBetHTML({ items: props.items, id: Date.now() }, props.language));
		} else {
			navigator.clipboard.writeText(GenerateSpectateComboBetHTML({ items: props.items, id: Date.now() }, props.spectateLanguage, props.betType, bannerTitle));
		}
	}
	return (
		<div className="modal fade" id="svgComboModal" tabIndex="-1" role="dialog" aria-labelledby="svgComboModalTitle" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div className="modal-content specific-class">
					<div className="modal-body d-flex flex-column">
						<ComboBetBanner 
							lang={props.apiType === "kambi" ? props.language : props.spectateLanguage} 
							bannerTitle={bannerTitle}
						/>
						<div className="form-group mt-5 w-100 px-3">
							<input
								type="text"
								className="form-control"
								id="net_worth_name"
								placeholder="Enter Banner Title"
								required
								value={bannerTitle}
								onChange={(e) => setBannerTitle(e.target.value)}
							/>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-dark" data-dismiss="modal">Close</button>
						<button type="button" className="btn btn-primary" onClick={() => {
							handleCopyToClickboard()
						}}>COPY BANNER</button>
					</div>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state) => ({
	items: state.bucket.items,
	apiType: state.bucket.apiType,
	spectateLanguage: state.spectate.language,
	betType: state.spectate.betType
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SvgComboModal);