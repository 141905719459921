const deDETranslation = {
  "3-Way": "SPIELRESULTAT",
  "Both Teams to Score": "BEIDE MANNSCHAFTEN SCHIESSEN TORE",
  "Draw No Bet": "BEI UNENTSCHIEDEN GELD ZURÜCK",
  "Total Goals Over/Under": "ALLE TORE ÜBER/UNTER",
  "Double Chance": "DOPPELTE CHANCE",
  "Correct Score" : "Korrektes Ergebnis",
  "Full Time Result" : "Endergebnis",
  "1st Half - 3-Way" : "1. Halbzeit - 3 Wege",
  "Handicap" : "Handicap",
  "2nd Half 3-Way" : "2. Halbzeit - 3 Wege",
  "1st Half - Both Teams to Score" : "1. Halbzeit - beide Teams treffen",
  "First Goalscorer" : "Erster Torschütze",
  "Anytime Goalscorer" : "Torschütze",
  "Highest Scoring Half" : "Halbzeit mit den meisten Toren",
  "Last Goalscorer" : "Letzter Torschütze",
  "Asian Handicap" : "Asiatisches Handicap",
  "Score in Both Halves" : "Tor in beiden Halbzeiten",
  "Win Both Halves" : "Gewinnt beide Halbzeiten",
  "Win Either Half" : "Gewinnt eine Halbzeit",
  "Odd or Even Total" : "Ungerade oder gerade",
  "To Win To Nil" : "Gewinnt zu Null",
  "Clean Sheet" : "Zu Null",
  "Match Result and Both Teams To Score" : "Endergebnis und beide Teams treffen",
  "Half-time Odd or Even Total" : "Halbzeit ungerade oder gerade",
  "Half-time Correct Score" : "Halbzeit korrektes Ergebnis",
  "Match Result and Total Goals Over/Under" : "Endgergebnis und Tore über/unter",
  "Half-time Handicap" : "Halbzeit Handicap",
  "Hat-trick" : "Hattrick",
  "Away Team Total Goals Over/Under" : "Auswärtsteam über/unter",
  "Half-time Totals Over/Under" : "Halbzeit Tore über/unter",
  "Home Team Total Goals Over/Under" : "Heimteam Tore über/unter",
  "Half-time Draw No Bet" : "Halbzeit Unentschieden keine Wette",
  "2nd Half Correct Score" : "2. Halbzeit korrektes Ergebnis",
  "Half-time/Full-time" : "Halbzeit/Endstand",
  "Total Corners Over/Under" : "Ecken über/unter",
  "Booking Points Over/Under" : "Gelbe und Rote Karten über/unter",
  "BetBuilder" : "BetBuilder",
  "MatchCombo" : "MatchCombo",
  "Home Team Total Corners Over/Under" : "Heimteam Ecken über/unter",
  "Away Team Total Corners Over/Under" : "Auswärtsteam über/unter",
  "Total Corners 1st Half Over/Under" : "Ecken 1. Halbzeit über/unter",
  "Total Corners 2nd Half Over/Under" : "Ecken 2. Halbzeit über/unter",
  "Home Team Booking Points Over/Under" : "Heimteam Gelbe und Rote Karten über/unter",
  "Away Team Booking Points Over/Under" : "Auswärtsteam Gelbe und Rote Karten über/unter",
  "Most Corners" : "Meiste Ecken",
  "1st Half - Most Corners" : "1. Halbzeit - meiste Ecken",
  "Player To Be Carded" : "Spieler wird verwarnt",
  "Player To Be Sent Off" : "Spieler erhält Platzverweis",
  "Player to Have One or More Assists" : "Spieler mit einem oder mehr Assists",
  "Player to Have Two or More Assists" : "Spieler mit zwei oder mehr Assists",
  "Player to Have Three or More Assists" : "Spieler mit drei oder mehr Assists",
  "Player to Score from Outside the Box" : "Spieler trifft von außerhalb des Strafraums",
  "Player to Score a Header" : "Spieler trifft per Kopf",
  "1st Half - Player to Score from Outside the Box" : "1. Halbzeit - Spieler trifft von außerhalb des Strafraums",
  "1st Half - Player to Score a Header" : "1. Halbzeit - Spieler trifft per Kopf",
  "Total shots at goal" : "Torschüsse gesamt",
  "Total shots on target" : "Schüsse aufs Tor gesamt",
  "Total passes" : "Pässe gesamt",
  "Home Team Total Shots at Goal" : "Heimteam Torschüsse gesamt",
  "Away Team Total Shots at Goal" : "Auswärtsteam Torschüsse gesamt",
  "Home Team Total Shots on Target" : "Heimteam Schüsse aufs Tor gesamt",
  "Away Team Total Shots on Target" : "Auswärtsteam Schüsse aufs Tor gesamt",
  "Home Team Total Passes" : "Heimteam Pässe gesamt",
  "Away Team Total Passes" : "Auswärtsteam Pässe gesamt",
  "oddsTerms": "*Quotenänderugen vorbehalten. Maximal zulässiger Einsatz gilt. Es gelten die AGB.",
  "Outright Winner": "Gesamtsieger",
  "To Finish In Top 4": "Top 4 Platzierung",
    "To Finish In Top 2": "Top 2 Platzierung",
  "To Finish In Top 6": "Top 6 Platzierung",
  "To Be Relegated": "Abstieg",
  "To Be Promoted": "Aufstieg",
  "Player Top Goalscorer": "Torschützenkönig",
  "Bet Now": "ANMELDEN!",
  "Bet Expired": "Wette abgelaufen",
  "Strength": "Stärke",
  "Attacking Potential": "Angriffspotential",
  "Defensive Potential": "Verteidigungspotential",
  "Poisson Distribussion": "Wahrscheinlichkeit",
  "Strength H2H": "Kader Direktvergleich",
  "Goals H2H": "Tore Direktvergleich",
  "Wins the Game": "Gewinnt das Spiel",
  "Germany": "Deutschland",
  "Scotland": "Schottland",
  "Hungary": "Ungarn",
  "Switzerland": "Schweiz",
  "Spain": "Spanien",
  "Croatia": "Kroatien",
  "Italy": "Italien",
  "Slovenia": "Slovenien",
  "Denmark": "Dänemark",
  "Serbia": "Serbien",
  "England": "England",
  "Austria": "Österreich",
  "France": "Frankreich",
  "Belgium": "Belgien",
  "Slovakia": "Slovakei",
  "Portugal": "Portugal",
  "Czech Republic": "Tschechische Republik",
  "Netherlands": "Niederlande",
  "Romania": "Romänien",
  "Poland": "Polen",
  "Georgia": "Georgien",
  "Ukraine": "Ukraine",
  "Turkey": "Türkei"
  };
  
  export default deDETranslation;